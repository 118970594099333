import { useUserContext } from "../components/contexts/UserContext";
import { useDocument } from "./useDocument";
import { UserModel } from "../models/user";
import { useMemo } from "react";
export const useUserTokens = (shouldSubscribe = true) => {
    const { user } = useUserContext();
    const userModel = useMemo(() => {
        return user.userId ? new UserModel(user.userId) : null;
    }, [user.userId]);
    const { document: userProfile, hasFetchedDocument: hasFetchedUserProfile } = useDocument({
        documentId: user.userId || null,
        documentModel: userModel,
        shouldSubscribe,
    });
    return {
        userProfile,
        hasFetchedUserProfile,
    };
};
