import { pageOrder, } from "shared/types/book";
import { Model } from "./model";
export const BOOK_COLLECTION = "books";
export const BOOK_STYLE_PREFIX = `Minimalist watercolor storybook sketch art with fluid lines.`;
export const BOOK_STYLE_SUFFIX = `The scene evokes a feeling of whimsy and storybook charm. Character depictions are exaggerated, cute and endearing. Characters should not look anime or like dolls. Do not include any text or numerical items in the image.`;
export const BOOK_STYLE = `\
${BOOK_STYLE_PREFIX}\
{{image_description}} \
${BOOK_STYLE_SUFFIX}`;
export class BookModel extends Model {
    static pageNeedsImage = (pageKey) => !!pageOrder.find(({ key }) => pageKey === key)?.needsImage;
    constructor(documentId) {
        super(documentId, BOOK_COLLECTION);
    }
    static createBook = async (args) => {
        return Model.create({
            ...args,
            createdAt: new Date(),
            updatedAt: new Date(),
            data: { ...args.data, isPublic: false, isFeatured: false },
            status: "NOT_STARTED",
        }, BOOK_COLLECTION);
    };
    static deleteBook = async (args) => {
        return Model.delete({ id: args.bookId }, BOOK_COLLECTION);
    };
    mergePage = async (pageKey, pageData) => {
        const data = {};
        Object.entries(pageData).forEach(([field, value]) => {
            data[`data.pages.${pageKey}.${field}`] = value;
        });
        if (!pageData.error) {
            data[`data.pages.${pageKey}.error`] = null;
        }
        await this.update(data);
    };
}
