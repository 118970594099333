import { jsx as _jsx } from "react/jsx-runtime";
// only want to listen to auth changes at top level
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, updateProfile, } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth, callables } from "../../firebase";
import { UserModel } from "../../models/user";
import { useNavigate } from "react-router-dom";
import LogRocket from "logrocket";
import { setUserId, setUserProperties } from "firebase/analytics";
import { analytics } from "../../firebase";
const UserContext = React.createContext({
    user: { userId: undefined, userIdToken: null, auth: null, profile: null },
    hasFetchedUser: false,
    handleSignUp: () => Promise.resolve(),
    handleLogin: () => Promise.resolve(),
    handleGoogleLogin: () => Promise.resolve(),
    handleSignOut: () => Promise.resolve(),
    getUserProfile: (userId) => Promise.resolve(),
});
export const useUserContext = () => React.useContext(UserContext);
export const UserContextProvider = ({ children, }) => {
    const navigate = useNavigate();
    const [hasFetchedUser, setHasFetchedUser] = useState(false);
    const [user, setUser] = useState({
        userId: undefined,
        userIdToken: null,
        auth: null,
        profile: null,
    });
    const handleLogRocketInit = (auth) => {
        if (!auth.currentUser?.uid)
            return;
        const userTraits = {};
        if (auth.currentUser.displayName) {
            userTraits.name = auth.currentUser.displayName;
        }
        if (auth.currentUser.email) {
            userTraits.email = auth.currentUser.email;
        }
        LogRocket.identify(auth.currentUser.uid, userTraits);
        if (analytics) {
            setUserId(analytics, auth.currentUser.uid);
            setUserProperties(analytics, userTraits);
        }
    };
    const handleAuthStateChanged = (authUser) => {
        if (authUser) {
            authUser?.getIdToken().then((idToken) => {
                setUser((prev) => {
                    return { ...prev, userIdToken: idToken };
                });
            });
            setUser((prev) => {
                return {
                    userId: authUser.uid,
                    userIdToken: prev.userIdToken || null,
                    auth: authUser || null,
                    profile: prev?.profile || null,
                };
            });
        }
        else {
            setUser({ userId: null, userIdToken: null, auth: null, profile: null });
        }
    };
    const getUserProfile = (userId) => {
        const userModel = new UserModel(userId);
        return userModel
            .get()
            .then((userProfile) => {
            setUser((prev) => {
                return {
                    userId,
                    userIdToken: prev?.userIdToken || null,
                    profile: userProfile,
                    auth: prev?.auth || null,
                };
            });
            setHasFetchedUser(true);
            return userProfile;
        })
            .catch(console.error);
    };
    useEffect(() => {
        if (auth.currentUser?.uid) {
            setUser((prev) => {
                return {
                    userId: auth.currentUser?.uid,
                    userIdToken: prev.userIdToken || null,
                    auth: auth.currentUser,
                    profile: prev?.profile || null,
                };
            });
            handleLogRocketInit(auth);
        }
        const unsubscribe = auth.onAuthStateChanged(handleAuthStateChanged);
        return () => {
            unsubscribe && unsubscribe();
        };
    }, [auth]);
    useEffect(() => {
        const userId = user?.userId;
        if (typeof userId !== "string" || !userId)
            return;
        getUserProfile(userId);
    }, [user?.userId]);
    const handleSignUp = async ({ name, email, password, }) => {
        try {
            const { user: createdUser } = await createUserWithEmailAndPassword(auth, email, password);
            await updateProfile(createdUser, { displayName: name });
            await callables.createUser({ userId: createdUser.uid, name });
        }
        catch (error) {
            console.error("Error signing up", error);
            return Promise.reject();
        }
    };
    const handleLogin = async ({ email, password, }) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        }
        catch (error) {
            console.error("Error logging in", error);
            return Promise.reject();
        }
    };
    const handleGoogleLogin = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const { user: authUser } = await signInWithPopup(auth, provider);
            const userId = authUser.uid;
            const userModel = new UserModel(userId);
            const userExists = await userModel.exists();
            if (!userExists) {
                await callables.createUser({ userId, name: authUser.displayName });
            }
        }
        catch (error) {
            console.error("Error logging in with Google", error);
            return Promise.reject();
        }
    };
    const handleSignOut = async () => {
        try {
            await auth.signOut();
            // signed out
        }
        catch (e) {
            // an error
        }
        setUser({ userId: null, userIdToken: null, auth: null, profile: null });
        navigate("/");
        return Promise.resolve();
    };
    return (_jsx(UserContext.Provider, { value: {
            user,
            hasFetchedUser,
            handleSignUp,
            handleLogin,
            handleGoogleLogin,
            handleSignOut,
            getUserProfile,
        }, children: children }));
};
