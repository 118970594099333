import { BookCollection } from "../models/bookCollection";
import { useCollection } from "./useCollection";
export const useBooks = (queryClause) => {
    const { collection: books, hasFetchedCollection: hasFetchedBooks, updateCollectionState: updateBooksState, } = useCollection(queryClause, BookCollection);
    return {
        books,
        hasFetchedBooks,
        updateBooksState,
    };
};
