"use client";
import React from "react";
export const MakeStoryContext = React.createContext({
    bookId: null,
    currentPageKey: "cover",
    handlers: {
        regenerateBook: () => {
            return;
        },
        updateBook: () => {
            return;
        },
        buyBook: () => Promise.resolve(),
        navigateBook: () => {
            return () => {
                return;
            };
        },
        generateImage: () => Promise.resolve(),
        updateCurrentStep: () => {
            return;
        },
        handleShowImageExplainerModal: () => {
            return;
        },
        handleCloseImageExplainerModal: () => {
            return;
        },
    },
});
export const useMakeStoryContext = () => React.useContext(MakeStoryContext);
