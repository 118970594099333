import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Joyride from "react-joyride";
import { Button } from "../Button";
const TourContent = ({ children }) => (_jsx("p", { className: "text-left -m-1 -mt-2", children: children }));
export const GuidedTour = ({ shouldPause, handleShowImageExplainerModal, }) => {
    return (_jsx(Joyride, { run: !shouldPause, disableOverlayClose: true, showProgress: true, continuous: true, spotlightClicks: true, styles: {
            options: {
                primaryColor: "#e74694",
            },
        }, locale: {
            last: "Close",
        }, callback: (args) => {
            if (args.status === "finished") {
                try {
                    localStorage.setItem("unicorntales_tour", "1");
                }
                catch (e) {
                    // do nothing
                }
            }
        }, steps: [
            {
                target: ".user-credits",
                content: (_jsx(TourContent, { children: "This tracks how many tokens you have to spend on new books or images. You can always purchase more for just a couple bucks!" })),
                disableBeacon: true,
                hideCloseButton: true,
                placement: "right",
            },
            {
                target: ".page-text",
                content: (_jsx(TourContent, { children: "Edit the text in this input field to change the text in the book preview" })),
                disableBeacon: true,
                hideCloseButton: true,
                placement: "right",
            },
            {
                target: ".page-image",
                content: (_jsxs(TourContent, { children: ["Click 'Gimme another image' to get another variation of the current image, or give specific feedback for a better next image.", _jsx("br", {}), _jsx(Button, { onClick: handleShowImageExplainerModal, as: "link", className: "mt-2 -mb-2 block text-sm", children: "Why would I need more images?" })] })),
                disableBeacon: true,
                hideCloseButton: true,
                placement: "right",
            },
            {
                target: ".buy-button",
                content: (_jsx(TourContent, { children: "When you're all finished editing your story, click this button to purchase the physical book!" })),
                disableBeacon: true,
                hideCloseButton: true,
            },
        ] }));
};
