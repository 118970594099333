import { OrderFormat } from "shared/types/order";
import { Model } from "./model";
export const ORDER_COLLECTION = "orders";
export class OrderModel extends Model {
    constructor(documentId) {
        super(documentId, ORDER_COLLECTION);
    }
    static createOrder = async ({ userId, data, }) => {
        const creationData = {
            format: OrderFormat.v1,
            userId,
            data,
            createdAt: new Date(),
            updatedAt: new Date(),
        };
        return Model.create(creationData, ORDER_COLLECTION);
    };
}
