import { env } from "../utils/env";
export const templateRoutes = {
    cover: "/templates/8.5x11/cover_preview.html",
    page: "/templates/8.5x11/page_preview.html",
    dedication: "/templates/8.5x11/dedication_preview.html",
    end: "/templates/8.5x11/end_preview.html?text=The%20End",
    branding: "/templates/8.5x11/branding_preview.html",
    // cover: "/templates/8x8/cover.html",
    // page: "/templates/8x8/page.html",
    // dedication: "/templates/8x8/dedication.html",
};
export const getTemplateRouteForPage = (page) => {
    if (!isNaN(Number(page))) {
        return templateRoutes.page;
    }
    return templateRoutes[page];
};
export const getIframeUrl = ({ pageTemplateKey, hostname, text, imageDownloadUrl, isLoadingImage = false, }) => {
    if ((typeof window === "undefined" && !hostname) || !pageTemplateKey) {
        return "";
    }
    const image = imageDownloadUrl;
    const host = window.location.href.replace(window.location.pathname, "");
    const route = getTemplateRouteForPage(pageTemplateKey);
    const url = new URL(`${host}${route}`);
    if (text) {
        url.searchParams.set("text", text);
    }
    if (image) {
        url.searchParams.set("img", image);
    }
    if (isLoadingImage) {
        url.searchParams.set("loading", "true");
    }
    url.searchParams.set("env", env.appEnv);
    url.searchParams.set("preview", "true");
    return url.href;
};
