import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { Button } from "../../../Button";
import { CharacterCard } from "../../CharacterCard";
import MakeCharacterModal from "../../Modals/MakeCharacterModal";
export const AddCharacters = ({ characters, setCharacters, }) => {
    const [characterOrder, setCharacterOrder] = useState([]);
    const [editingCharacter, setEditingCharacter] = useState(null);
    const [isCharacterModalOpen, setIsCharacterModalOpen] = useState(false);
    const handleDeleteCharacter = (name) => () => {
        setCharacters((prev) => {
            const next = { ...prev };
            delete next[name];
            return next;
        });
        setCharacterOrder((prev) => {
            return prev.filter((charName) => {
                return charName !== name;
            });
        });
    };
    const handleClickEditCharacter = (name) => () => {
        setEditingCharacter(name);
        setIsCharacterModalOpen(true);
    };
    const handleUpdateCharacter = (name) => (character) => {
        if (!name) {
            setCharacters((prev) => {
                return { ...prev, [character.name]: character };
            });
            setCharacterOrder((prev) => {
                return [...prev, character.name];
            });
        }
        else {
            setCharacters((prev) => {
                const next = { ...prev };
                if (next[name]) {
                    delete next[name];
                }
                next[character.name] = {
                    name: character.name,
                    description: character.description,
                    appearance: character.appearance,
                };
                return next;
            });
            setCharacterOrder((prev) => {
                return prev.map((charName) => {
                    if (charName === name)
                        return character.name;
                    return charName;
                });
            });
        }
        handleSaveCharacter();
    };
    const handleSaveCharacter = () => {
        setEditingCharacter(null);
    };
    const handleClickAddCharacter = () => {
        setEditingCharacter(null);
        setIsCharacterModalOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx("h1", { className: "text-2xl my-2 mt-8", children: "First, add your characters" }), _jsxs("p", { className: " mb-8", children: ["We find ", _jsx("strong", { children: "1-2 characters" }), " work best for our robots."] }), characterOrder.length > 0 && (_jsx("div", { className: "flex flex-col mt-0 mb-4", children: characterOrder.map((name) => {
                    const { description, appearance } = characters[name];
                    return (_jsx(CharacterCard, { name: name, description: description, appearance: appearance, handleClickEditCharacter: handleClickEditCharacter(name), handleDeleteCharacter: handleDeleteCharacter(name) }, name));
                }) })), _jsxs(Button, { onClick: handleClickAddCharacter, className: "w-auto font-bold mr-auto", children: [_jsx(IoMdAdd, { className: "" }), "Add character"] }), _jsx(MakeCharacterModal, { isOpen: isCharacterModalOpen, character: editingCharacter ? characters[editingCharacter] : null, handleCloseModal: () => {
                    setIsCharacterModalOpen(false);
                }, handleDeleteCharacters: () => {
                    if (!editingCharacter)
                        return;
                    handleDeleteCharacter(editingCharacter);
                }, handleUpdateCharacters: (character) => {
                    handleUpdateCharacter(editingCharacter)(character);
                } }), _jsx("hr", { className: "mt-8 mb-4" })] }));
};
