import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "../../../Button";
import { useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { formatListOfThings } from "../../../../helpers/text";
import { getMissingCharacters } from "../../../../helpers/character";
import { Textarea } from "flowbite-react";
import { IoSparklesSharp } from "react-icons/io5";
import { StoryType } from "shared/types/callables";
const BedtimeStory = ({ characters }) => {
    const characterNames = Object.keys(characters);
    const plural = characterNames.length > 1 ? "" : "s";
    return (_jsxs("div", { className: "pt-4", children: [_jsxs("p", { children: ["Bedtime stories have simple, rhythmic scenes that follow a predictable pattern. For example, your story could be about a little boy that searches room by room for all of their scattered crayons, or about a little girl trying to teach a dog various tricks, but the dog just barks. Books that follow this pattern are beloved classics", " ", _jsx("em", { children: "Goodnight Moon" }), ", ", _jsx("em", { children: "No, David!" }), " and", " ", _jsx("em", { children: "Guess How Much I Love You" }), "."] }), _jsx("div", { className: "mt-8 mb-2 flex justify-between", children: _jsxs("h3", { className: "font-bold text-xl font-header", children: ["What is the recurring interaction or theme that", " ", _jsx("span", { className: "text-pink-600", children: formatListOfThings(characterNames) }), " ", "engage", plural, " in throughout your story?"] }) })] }));
};
const SillyStory = ({ characters }) => {
    const characterNames = Object.keys(characters);
    const doOrDoes = characterNames.length > 1 ? "do" : "does";
    return (_jsxs("div", { className: "pt-4", children: [_jsxs("p", { children: ["Silly stories center on a character who starts the book in perfectly ordinary circumstances, but finds themselves in increasingly silly situations, only to have them be resolved at the last second due to an equally silly reason. Books that follow this pattern are beloved classics ", _jsx("em", { children: "If You Give A Mouse A Cookie" }), ",", " ", _jsx("em", { children: "The Cat In The Hat" }), " and", " ", _jsx("em", { children: "There Was an Old Lady Who Swallowed a Fly" }), "."] }), _jsx("div", { className: "mt-8 mb-2 flex justify-between", children: _jsxs("h3", { className: "font-bold text-xl font-header", children: ["What is a normal activity that", " ", _jsx("span", { className: "text-pink-600", children: formatListOfThings(characterNames) }), " ", doOrDoes, " at the beginning, and what is one ridiculous thing that could lead to?"] }) })] }));
};
const FairyTaleStory = ({ characters }) => {
    return _jsx("div", {});
};
const plotForm = {
    [StoryType.bedtime]: BedtimeStory,
    [StoryType.silly]: SillyStory,
    [StoryType.traditional]: FairyTaleStory,
};
export const AddStoryDetails = ({ storyType, characters, synopsis, setSynopsis, storyIdeas, handleGenerateIdeas, isGeneratingIdeas, }) => {
    // const [localSynopsis, setLocalSynopsis] = useState(synopsis);
    const [synopsisError, setSynopsisError] = useState(``);
    const Form = plotForm[storyType] || null;
    if (!Form)
        return null;
    useEffect(() => {
        const missingCharacters = getMissingCharacters(characters, synopsis);
        if (synopsis && missingCharacters.length) {
            const isOrAre = missingCharacters.length === 1 ? "is" : "are";
            setSynopsisError(`${formatListOfThings(missingCharacters)} ${isOrAre} missing from your synopsis. Please make sure to mention all characters by name, or delete them if not in the story.`);
        }
        else {
            setSynopsisError("");
        }
    }, [synopsis, Object.keys(characters)]);
    return (_jsxs("div", { className: "", children: [_jsx("h1", { className: "text-2xl my-2 mt-8 mb-2", children: "Last step! Tell us a bit about what happens in the story" }), _jsx(Form, { characters: characters }), _jsx(Textarea, { className: "mt-4 bg-white border-none shadow h-[6rem]", value: synopsis, onChange: (e) => {
                    setSynopsis(e.target.value);
                } }), synopsisError && (_jsx("p", { className: "text-gray-500 text-sm mt-2 font-bold", children: synopsisError })), storyIdeas.length > 0 && (_jsx("h2", { className: "font--header text-lg mt-4 mb-2", children: "Suggestions from Our Robots" })), storyIdeas.map((idea) => {
                const activeClass = idea === synopsis ? `border border-pink-600 text-pink-600` : "";
                return (_jsx("button", { className: `${activeClass} w-full text-sm hover:bg-gray-50 text-left my-1 bg-white shadow rounded-lg p-4 font--subheadline`, onClick: () => {
                        setSynopsis(idea);
                    }, children: idea }, idea));
            }), _jsx(Button
            // className="mb-2 bg-gray-50 border-none text-pink-600"
            // color="default"
            , { 
                // className="mb-2 bg-gray-50 border-none text-pink-600"
                // color="default"
                disabled: isGeneratingIdeas, className: "mt-4", color: "alternate", onClick: () => handleGenerateIdeas(storyType), children: isGeneratingIdeas ? (_jsxs(_Fragment, { children: [_jsx(IoSparklesSharp, { className: "mr-2" }), "Generating..."] })) : (_jsxs(_Fragment, { children: [_jsx(IoSparklesSharp, { className: "mr-2" }), " Give me some ideas (", " ", _jsx(FaCoins, { className: "ml-1 mr-1" }), " 5"] })) }), _jsx("hr", { className: "mt-4 mb-4" })] }));
};
