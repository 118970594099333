import { env } from "./env";
export const fetchProtected = async ({ url, method, userIdToken, body, }) => {
    if (!userIdToken) {
        throw new Error("Unable to fetch, userIdToken not defined");
    }
    return fetch(`${env.firebaseApiUrl}/${url}`, {
        method,
        mode: "cors",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userIdToken}`,
        },
        body: body ? JSON.stringify(body) : undefined,
    });
};
