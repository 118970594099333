"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar } from "flowbite-react";
import { Button } from "./Button";
import { useState } from "react";
import { useUserContext } from "./contexts/UserContext";
import Modal from "./Modal";
import SignUpLogin from "./Login/SignUpLogin";
import { UserProfileLinks } from "./UserProfileLinks";
import { LOGOS } from "../helpers/illustration";
const links = [
    {
        path: `/view`,
        text: "View Examples",
    },
    {
        path: "/make-story",
        text: "Make a Story",
    },
    {
        path: `/faq`,
        text: "FAQs",
    },
];
export const linkTheme = {
    active: {
        on: "text-pink-500 font-semibold dark:text-white md:bg-transparent md:text-pink-500",
        off: "md:hover:text-pink-500 md:active:text-pink-500 md:focus:text-pink-500",
    },
};
export const Header = () => {
    const { user } = useUserContext();
    const [modalType, setModalType] = useState(null);
    const handleCloseModal = () => setModalType(null);
    const getModalHeader = () => {
        if (!modalType)
            return "";
        const modalHeaderMap = {
            login: "Login / Sign up",
            "purchase-credits": "Purchase credits",
        };
        return modalHeaderMap[modalType] || "";
    };
    const getModalChildren = () => {
        if (!modalType)
            return "";
        const modalHeaderMap = {
            login: _jsx(SignUpLogin, { handleLoginCallback: handleCloseModal }),
        };
        return modalHeaderMap[modalType] || "";
    };
    return (_jsxs(Navbar, { fluid: true, rounded: true, className: "z-10 relative shadow-md sticky top-0", children: [_jsxs(Navbar.Brand, { href: "/", children: [_jsx("img", { src: LOGOS[512], alt: "UnicornTales Logo", className: "mr-3 h-6 sm:h-9" }), _jsx("span", { className: "font-header self-center whitespace-nowrap text-xl md:text-2xl font-semibold dark:text-white", children: "UnicornTales" })] }), _jsx(Navbar.Toggle, {}), _jsxs(Navbar.Collapse, { children: [links.map(({ path, text }) => {
                        return (_jsx("div", { className: "md:self-center", children: _jsx(Navbar.Link, { href: path, active: location.pathname.includes(path), theme: linkTheme, children: text }) }, path));
                    }), user?.userId ? (_jsx(UserProfileLinks, {})) : (_jsx(Button, { className: "ml-3 md:ml-0", as: "link", onClick: () => setModalType("login"), children: "Login" })), _jsx(Modal, { header: getModalHeader(), isOpen: modalType !== null, handleClose: handleCloseModal, size: "md", children: getModalChildren() })] })] }));
};
