import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HiInformationCircle } from "react-icons/hi";
import { Alert, Modal } from "flowbite-react";
import { IoMdCheckmark } from "react-icons/io";
import { ErrorCode } from "shared/types/error";
import { OutOfTokensMessage, OutOfTokensTitle, } from "../Admin/OutOfTokensMessage";
import { GeneralErrorMessage, GeneralErrorTitle, } from "../Admin/GeneralErrorMessage";
const ErrorContext = React.createContext({
    handleError: (e) => {
        return;
    },
    setMessage: () => {
        return;
    },
    handleCloseModal: () => {
        return;
    },
});
const EntirePageError = ({ error }) => {
    return (_jsxs("div", { className: "fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center", children: [_jsx("h1", { className: "text-xl mb-12", children: "Uh oh! Something went wrong" }), _jsx("h4", { children: error?.message })] }));
};
export const useErrorContext = () => useContext(ErrorContext);
export const ErrorContextProvider = ({ children, }) => {
    const [alert, setAlert] = React.useState(undefined);
    const [modalData, setModalData] = React.useState(undefined);
    const setMessage = ({ messageUX, messageType, ...message }) => {
        if (messageUX === "alert") {
            setAlert({ type: messageType, ...message });
            setModalData(undefined);
        }
        else if (messageUX === "modal") {
            setModalData({ type: messageType, ...message });
            setAlert(undefined);
        }
    };
    const logError = (error, info) => {
        // TODO: Do something with the error, e.g. log to an external API
    };
    useEffect(() => {
        let timeout;
        if (alert) {
            timeout = setTimeout(() => {
                setAlert(undefined);
            }, 3000);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [alert]);
    const handleCloseModal = () => {
        setModalData(undefined);
    };
    const handleError = (error) => {
        const e = error;
        if (e.message === ErrorCode.OutOfTokens) {
            setMessage({
                messageType: "error",
                messageUX: "modal",
                title: _jsx(OutOfTokensTitle, {}),
                message: _jsx(OutOfTokensMessage, {}),
            });
        }
        else if (e.message === ErrorCode.ApiUnavailable) {
            setMessage({
                messageType: "error",
                messageUX: "modal",
                title: _jsx(GeneralErrorTitle, {}),
                message: _jsx(GeneralErrorMessage, {}),
                stack: e.stack,
            });
        }
        else {
            setMessage({
                messageUX: "alert",
                messageType: "error",
                title: `${e.message}`,
                message: `${e.toString()}`,
                stack: e.stack,
            });
        }
    };
    return (_jsx(ErrorContext.Provider, { value: { setMessage, handleError, handleCloseModal }, children: _jsx(ErrorBoundary, { onError: logError, fallbackRender: EntirePageError, children: _jsxs(_Fragment, { children: [children, alert && (_jsxs(Alert, { className: "fixed m-4 top-0 right-0 mt-16", color: alert?.type === "error" ? "failure" : alert?.type, icon: alert?.type === "success" ? IoMdCheckmark : HiInformationCircle, children: [alert?.type === "error" && (_jsx("span", { className: "font-medium", children: "Attention!" })), " ", alert?.title] })), _jsxs(Modal, { show: !!modalData, onClose: handleCloseModal, size: "lg", children: [_jsx(Modal.Header, { className: "border-none pl-0 pb-0 m-0" }), _jsxs(Modal.Body, { children: [_jsx("h2", { className: "font-header text-2xl mb-4 text-center bold", children: modalData?.title }), _jsx("div", { className: "text-center mb-4", children: modalData?.message })] })] })] }) }) }));
};
