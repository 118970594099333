"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cleanInput } from "../../helpers/input";
import { Button } from "../Button";
import { useState } from "react";
import { Tooltip } from "flowbite-react";
import Textarea from "react-textarea-autosize";
import { BOOK_STYLE_PREFIX, BOOK_STYLE_SUFFIX, } from "../../models/book";
const imageFeedbackTooltip = `For example, "the little boy should be smiling" or "the woman has brown hair". Leave this blank to see another variation of the current image.`;
export const ImageFeedbackForm = ({ isLoadingImages, selectedImageDescription, handleFetchIllustration, }) => {
    const [imageFeedback, setImageFeedback] = useState("");
    const handleUpdateImageFeedback = (e) => {
        setImageFeedback(cleanInput(e.target.value));
    };
    console.log(selectedImageDescription
        .replace(/\n/g, "")
        .replace(BOOK_STYLE_PREFIX, "")
        .replace(BOOK_STYLE_SUFFIX, ""));
    return (_jsxs("div", { className: "mt-4", children: [_jsx("div", { className: "flex items-center mb-1 ", children: _jsx("p", { className: "text-sm", children: "What do you want to change about the image?" }) }), _jsx(Textarea, { defaultValue: imageFeedback, onChange: handleUpdateImageFeedback, className: "page-text border w-full rounded-md text-sm border-gray-300 leading-5 text-gray-900" }), _jsx(Tooltip, { style: "light", content: _jsxs("span", { children: ["This text was used to create the current image. Image feedback will tweak this prompt.", _jsxs("p", { className: "italic mt-2", children: ["\"", selectedImageDescription
                                    .replace(/\n/g, " ")
                                    .replace(BOOK_STYLE_PREFIX, "")
                                    .replace(BOOK_STYLE_SUFFIX, ""), "\""] })] }), trigger: "click", children: _jsx("div", { className: "flex items-center mb-1 hover:text-pink-500 cursor-pointer", children: _jsx("p", { className: "text-xs my-1", children: "What was the previous image prompt?" }) }) }), _jsx(Button, { size: "sm", color: "alternate", className: "w-full mt-4 text-sm", onClick: () => handleFetchIllustration(imageFeedback), disabled: isLoadingImages, children: isLoadingImages
                    ? "Loading..."
                    : imageFeedback
                        ? "Revise my image"
                        : "Gimme another image" })] }));
};
