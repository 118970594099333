"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useLayoutEffect, useCallback } from "react";
import { Sidebar } from "flowbite-react";
import { CreateCharactersAndSynopsis } from "../CreateCharactersAndSynopsis";
import { SidebarPage } from "./SidebarPage";
import { SidebarSection } from "./SidebarSection";
import { BsChevronDoubleUp, BsChevronDoubleDown } from "react-icons/bs";
import { Button } from "../../Button";
import { useMakeStoryContext } from "../../contexts/MakeStoryContext";
import { pageOrder } from "shared/types/book";
export const StorySidebar = () => {
    const { handlers } = useMakeStoryContext();
    const [isSmallBreakpoint, setIsSmallBreakpoint] = useState(false);
    const [showFullSidebar, setShowFullSidebar] = useState(false);
    useLayoutEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth < 768;
            setIsSmallBreakpoint(isSmall);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleClickButton = useCallback(() => {
        if (!isSmallBreakpoint)
            return;
        setShowFullSidebar((prev) => !prev);
    }, [isSmallBreakpoint]);
    const hideSidebar = isSmallBreakpoint && !showFullSidebar;
    return (_jsxs(Sidebar, { className: `story-sidebar w-full md:w-80 h-auto md:h-[93vh] md:relative shadow-sm`, children: [_jsx(Button, { className: "border-b p-2 rounded-none w-screen md:w-full -mx-2 -mt-4 md:mx-0 md:mt-0 mb-4 text-lg font-bold", onClick: handlers.buyBook, children: "Buy Book" }), isSmallBreakpoint && (_jsxs("div", { onClick: handleClickButton, className: "transition w-full p-1 uppercase text-center font-semibold text-lg text-gray-700 flex items-center justify-center cursor-pointer border-b mb-4 pb-4", children: [!hideSidebar ? "Close" : "Edit", !hideSidebar ? (_jsx(BsChevronDoubleUp, { className: "ml-1" })) : (_jsx(BsChevronDoubleDown, { className: "ml-1" }))] })), !hideSidebar && (_jsxs(Sidebar.Items, { children: [_jsx(Sidebar.ItemGroup, { className: "md:w-64 book-info ", children: _jsx(Sidebar.Collapse, { label: `Book Info`, className: "font-bold flex-row-reverse", children: _jsx(SidebarSection, { children: _jsx(CreateCharactersAndSynopsis, {}) }) }) }), _jsx(Sidebar.ItemGroup, { children: pageOrder.map(({ key, name }) => {
                            return _jsx(SidebarPage, { pageKey: key, pageName: name }, key);
                        }) })] }))] }));
};
