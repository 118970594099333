import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import SignUpLogin from "./SignUpLogin";
import { useQueryParams } from "../../hooks/useQueryParams";
const LoginPage = () => {
    const queryParams = useQueryParams();
    const navigate = useNavigate();
    const handleRedirect = () => {
        const route = queryParams.route || "/";
        navigate(route);
    };
    return (_jsxs("div", { className: "max-w-lg p-10 md:p-10 m-auto", children: [_jsx("h2", { className: "text-xl md:text-3xl text-center my-4", children: "Login / Sign up" }), _jsx(SignUpLogin, { handleLoginCallback: handleRedirect })] }));
};
export default LoginPage;
