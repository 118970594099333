import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BsMoonStarsFill } from "react-icons/bs";
import { GiBalloons, GiPathDistance } from "react-icons/gi";
import { Checkbox, Label } from "flowbite-react";
import { StoryType } from "shared/types/callables";
const plotInfo = [
    {
        id: StoryType.bedtime,
        title: "Bedtime story",
        description: (_jsxs(_Fragment, { children: ["Sweet, simple stories that lull a child to sleep ", _jsx("br", {})] })),
        icon: BsMoonStarsFill,
    },
    {
        id: StoryType.silly,
        title: "Silly story",
        description: (_jsxs(_Fragment, { children: ["Whimsical tales featuring a series of absurd situations ", _jsx("br", {})] })),
        icon: GiBalloons,
    },
    {
        id: "traditional",
        title: "Traditional story",
        description: (_jsxs(_Fragment, { children: ["Classic story structure with a conflict and resolution ", _jsx("br", {}), " "] })),
        icon: GiPathDistance,
    },
];
const PlotCard = ({ title, description, icon: Icon, onClick, isActive, }) => {
    const activeClass = isActive ? `border border-pink-500 text-pink-600` : "";
    return (_jsx("button", { onClick: onClick, className: "w-full p-2 sm:w-1/3 min-w-[300px]", children: _jsxs("div", { className: `${activeClass} text-center bg-white hover:bg-gray-50 cursor-pointer shadow rounded-md flex flex-col items-center justify-center h-[200px] p-3`, children: [_jsx(Icon, { className: "text-2xl" }), _jsx("h1", { className: "my-2 text-lg", children: title }), _jsx("p", { className: "text-sm", children: description })] }) }));
};
export const ChooseStoryType = ({ storyType, setStoryType, setShouldRhyme, }) => {
    const handleClickStoryType = (newStoryType) => () => {
        setStoryType(newStoryType);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mb-6", children: [_jsx("h1", { className: "text-2xl my-2 mt-8 mb-2", children: "Great! Now what kind of story do you want?" }), _jsx("p", { className: "mb-6", children: "This will help our robots write what you have in mind" }), _jsx("div", { className: "flex flex-wrap", children: plotInfo.map((plot) => {
                            const { id, title, description, icon } = plot;
                            return (_jsx(PlotCard, { title: title, description: description, icon: icon, onClick: handleClickStoryType(id), isActive: storyType === id }, id));
                        }) }), _jsxs("div", { className: "my-4 block text-md", children: [_jsx(Checkbox, { id: "rhyme", color: "pink", onChange: (event) => {
                                    const isChecked = event.target.checked;
                                    setShouldRhyme(isChecked);
                                } }), " ", _jsx(Label, { className: "pl-2 text-md cursor-pointer", htmlFor: "rhyme", children: "I want my story to rhyme" })] })] }), _jsx("hr", { className: "mt-4 mb-4" })] }));
};
