"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ErrorCode } from "shared/types/error";
import { OutOfTokensMessage } from "../components/Admin/OutOfTokensMessage";
import { ILLUSTRATIONS } from "../helpers/illustration";
export const ERROR_CODE_MESSAGE_MAP = {
    [ErrorCode.ApiUnavailable]: "There was a problem reaching out to our robots. Please try again later.",
    [ErrorCode.ImageFailedContentFilter]: "The image request was blocked due to a content filter violation. Please edit the image prompt under `Advanced` and try again.",
    [ErrorCode.ImageFailedTimeout]: "There was a problem reaching out to our image robots. Please try again later.",
    [ErrorCode.OutOfTokens]: _jsx(OutOfTokensMessage, {}),
};
export const EXAMPLE_CHARACTERS = [
    {
        name: "Barbara",
        description: "A tall light-skinned woman with blue hair and spectacles",
    },
    {
        name: "Augie",
        description: "A light tan lab / hound mix",
    },
    {
        name: "Giggles",
        description: "A tall giraffe who wears oversized hats",
    },
    {
        name: "Maximus",
        description: "A young black boy with curly hair",
    },
    {
        name: "Sir Hopsalot",
        description: "A frog with a knight's helmet and cape.",
    },
    {
        name: "Professor Puzzles",
        description: "An freckle-faced old man with curly white hair",
    },
];
export const EXAMPLE_SYNOPSIS = [
    "{characters[0]} and {characters} sail around the world to find the cure to dragonpox. Along the way they fight monsters and make new friends. The story should have a happy ending.",
    "{characters[0]} recruits {characters} to help cook the world's largest pizza. They use lots of ingredients and kitchen tools to make it work. The story should read like a nursery rhyme.",
    "{characters[0]} gets lost in the woods during a storm after chasing butterflies through the woods, but is eventually found by {characters}",
    "{characters[0]} and {characters} get into a fight about sharing a toy, but end up learning the value of sharing.",
    "{characters} {isOrAre} being haunted by a ghost. At first it's very scary but then they end up becoming friends. The story should be spooky.",
    "{characters} {isOrAre} a superstar pop singing sensation!",
    "{characters} {isOrAre} helping Santa update his Naughty and Nice list from paper to an electronic system",
];
export const LOADING_DATA = [
    {
        text: "Summoning a muse for inspiration",
        image: ILLUSTRATIONS.MUSE,
    },
    {
        text: "Sketching the blueprint of a plot",
        image: ILLUSTRATIONS.BLUEPRINT,
    },
    {
        text: "Consulting our lovely robot writers",
        image: ILLUSTRATIONS.ROBOT_WRITERS,
    },
    {
        text: "Navigating the river of story arcs",
        image: ILLUSTRATIONS.RIVER,
    },
    {
        text: "Taming the wilds of unruly subplots",
        image: ILLUSTRATIONS.SUBPLOTS,
    },
    {
        text: "Crafting the lure of the opening line",
        image: ILLUSTRATIONS.MONOLOGUE,
    },
    {
        text: "Breathing life into characters",
        image: ILLUSTRATIONS.DOLLS,
    },
    {
        text: "Sculpting scenes with care",
        image: ILLUSTRATIONS.SCULPTING,
    },
    {
        text: "Conversing with the ghouls of good editing",
        image: ILLUSTRATIONS.GHOULS,
    },
    {
        text: "Fighting with commas and colons",
        image: ILLUSTRATIONS.COMMAS,
    },
    {
        text: "Polishing sentences until they shine",
        image: ILLUSTRATIONS.POLISHING,
    },
    {
        text: "Selecting the finest paper",
        image: ILLUSTRATIONS.PAPER,
    },
    {
        text: "Transcribing the text in our most elegant calligraphy",
        image: ILLUSTRATIONS.CALLIGRAPHY,
    },
    {
        text: "Infusing pages with heart and soul",
        image: ILLUSTRATIONS.INFUSING,
    },
    {
        text: "Embarking on the quest for the perfect title",
        image: ILLUSTRATIONS.BRAINSTORM,
    },
    {
        text: "Choosing a cover to enchant the eye",
        image: ILLUSTRATIONS.ART_SHOW,
    },
    {
        text: "Illuminating the story with hand painted illustrations",
        image: ILLUSTRATIONS.PAINTING,
    },
    {
        text: "Letting the ink dry",
        image: ILLUSTRATIONS.INK_DRYING,
    },
    {
        text: "Binding the pages",
        image: ILLUSTRATIONS.BOOKBINDING,
    },
    {
        text: "Adding a sprinkle of fairy dust",
        image: ILLUSTRATIONS.MAGIC_DUST,
    },
];
export const SYNOPSIS_CHAR_LIMIT = 1500;
export const CHARACTER_CHAR_LIMIT = 70;
