import { useEffect, useState, useRef } from "react";
export const useCollection = (queryClause, CollectionModel) => {
    const [collection, setCollection] = useState([]);
    const [hasFetchedCollection, setHasFetchedCollection] = useState(false);
    const collectionModel = useRef(null);
    const getInitialCollection = async (collection) => {
        if (!queryClause)
            return;
        try {
            const collectionData = await collection.get(queryClause);
            setCollection(collectionData.filter((val) => !val?.archivedAt));
        }
        catch (e) {
            console.error(e);
            setCollection([]);
        }
        setHasFetchedCollection(true);
    };
    useEffect(() => {
        if (!collectionModel.current) {
            collectionModel.current = new CollectionModel();
        }
        getInitialCollection(collectionModel.current);
        return () => {
            collectionModel.current = null;
        };
    }, [JSON.stringify(queryClause), CollectionModel]);
    return {
        collection,
        hasFetchedCollection,
        updateCollectionState: setCollection,
    };
};
