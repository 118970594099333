import { useDocument } from "./useDocument";
export const useBookStatus = ({ bookId, bookModel, }) => {
    const { document: bookStatus, updateDocumentState: setBookStatus } = useDocument({
        documentId: bookId,
        documentModel: bookModel,
        shouldSubscribe: true,
        reducer: `status`,
    });
    return { bookStatus, setBookStatus };
};
