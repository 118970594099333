import { useMemo } from "react";
import { useLocation } from "react-router-dom";
export const useQueryParams = () => {
    const { search } = useLocation();
    const queryParams = useMemo(() => {
        const paramObject = new URLSearchParams(search);
        const params = {};
        paramObject.forEach((value, key) => {
            params[key.replace("?", "")] = value;
        });
        return params;
    }, [search]);
    return queryParams;
};
