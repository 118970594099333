const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
// todo have jake tell me why timezones matter
export const formatShippingDisplayDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth();
    return `${MONTHS[month]} ${date.getDate()}`;
};
const addLeadingZero = (num) => num < 10 ? `0${num}` : num;
export const formatOrderDisplayDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const dayOfMonth = addLeadingZero(date.getDate());
    const hours = date.getHours();
    const amOrPm = hours > 12 ? 'pm' : 'am';
    const hoursIn12HrDay = hours > 12 ? hours - 12 : hours;
    const formattedHours = addLeadingZero(hoursIn12HrDay);
    const formattedMinutes = addLeadingZero(date.getMinutes());
    return `${month}/${dayOfMonth}/${date.getFullYear()} ${formattedHours}:${formattedMinutes}${amOrPm}`;
};
export const formatOrderShippingDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const dayOfMonth = addLeadingZero(date.getDate());
    return `${month}/${dayOfMonth}/${date.getFullYear()}`;
};
