"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "flowbite-react";
import { Button } from "../../Button";
import { ILLUSTRATIONS } from "../../../helpers/illustration";
export const MissingBookDataModal = ({ isOpen, handleFinishBookCreation, handleCloseModal, }) => {
    return (_jsxs(Modal, { id: "book-data-modal", show: isOpen, onClose: handleCloseModal, size: "xl", children: [_jsx(Modal.Header, { className: "border-none -m-2 -mb-4" }), _jsx(Modal.Body, { children: _jsxs("div", { children: [_jsx("img", { className: "h-[14rem] m-auto", src: ILLUSTRATIONS.MAKE_STORY_FAILURE }), _jsx("h2", { className: "text-5xl text-center mt-8", children: "Some parts are missing..." }), _jsxs("p", { className: "text-center my-8", children: ["Looks like our robots had some trouble assembling all parts of your book.", _jsx("br", {}), " Click the button to try again."] }), _jsx(Button, { className: "w-full ml-1", onClick: () => {
                                handleFinishBookCreation();
                                handleCloseModal();
                            }, children: "Finish book creation" })] }) })] }));
};
