import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
const GlobalContext = React.createContext({
    showCreditsModal: false,
    handleShowCreditsModal: () => {
        return;
    },
});
export const useGlobalContext = () => React.useContext(GlobalContext);
export const GlobalContextProvider = ({ children, }) => {
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const handleShowCreditsModal = (shouldShow) => {
        setShowCreditsModal(shouldShow);
    };
    return (_jsx(GlobalContext.Provider, { value: { showCreditsModal, handleShowCreditsModal }, children: children }));
};
