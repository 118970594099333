import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { pageOrder } from "shared/types/book";
export const getDownloadUrl = async (imageUri) => {
    const imageRef = ref(storage, imageUri);
    const downloadUrl = await getDownloadURL(imageRef);
    return downloadUrl;
};
const PAGE_KEY_NEEDS_IMAGE = pageOrder.reduce((acc, page) => {
    return { ...acc, [page.key]: page.needsImage };
}, {});
