"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Spinner } from "flowbite-react";
import { usePublicImage } from "../../../hooks/usePublicImage";
export const SidebarImage = ({ imageUri, handleClickImage, selected, }) => {
    const imageSrc = usePublicImage(imageUri);
    const [imageLoaded, setImageLoaded] = useState(!!imageSrc);
    const handleImageLoaded = () => setImageLoaded(true);
    const id = imageUri.match(/([^/]+).png/)?.[1];
    return (_jsx("div", { className: "p-1 w-2/4 md:h-28 ", children: _jsxs("div", { className: `relative h-full w-full flex items-center justify-center cursor-pointer rounded-md `, children: [imageSrc && (_jsx("img", { src: imageSrc, className: `w-full h-full rounded-md hover:border-pink-500 hover:border ${selected ? "border-pink-500 border" : ""}`, onClick: handleClickImage, onLoad: handleImageLoaded, id: id })), !imageLoaded && (_jsx("div", { className: "w-full rounded-md h-full hover:border-pink-500 hover:border", children: _jsx("div", { className: "w-full h-full rounded-md bg-white  flex items-center justify-center", children: _jsx(Spinner, { color: "pink", size: "xl" }) }) }))] }) }));
};
