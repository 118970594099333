import { db } from "../firebase";
import { collection, getDoc, doc, addDoc, onSnapshot, updateDoc, deleteDoc, } from "firebase/firestore";
import merge from "lodash.merge";
import { v4 } from "uuid";
export const BOOK_COLLECTION = "books";
export class Model {
    docRef;
    state;
    subscriberCallbacks;
    unsubscriptions;
    constructor(documentId, collectionType) {
        this.docRef = doc(db, collectionType, documentId);
        this.state = null;
        this.subscriberCallbacks = new Map();
        const unsubscribe = onSnapshot(this.docRef, (docSnapshot) => {
            let doc = null;
            if (docSnapshot.exists()) {
                doc = { id: docSnapshot.id, ...docSnapshot.data() };
            }
            this.state = doc;
            Array.from(this.subscriberCallbacks.values()).forEach((callback) => {
                callback(doc);
            });
        });
        this.unsubscriptions = [unsubscribe];
    }
    static create = async (args, collectionType) => {
        const docRef = await addDoc(collection(db, collectionType), args);
        return { ...args, id: docRef.id };
    };
    static delete = async (args, collectionType) => {
        const docRef = doc(db, collectionType, args.id);
        await deleteDoc(docRef);
    };
    get = async () => {
        const docSnap = await getDoc(this.docRef);
        return { id: docSnap.id, ...docSnap.data() };
    };
    exists = async () => {
        const docSnap = await getDoc(this.docRef);
        return !!docSnap?.data();
    };
    subscribe = (callback) => {
        const id = v4();
        this.subscriberCallbacks.set(id, callback);
        return () => {
            this.subscriberCallbacks.delete(id);
        };
    };
    unsubscribe = () => {
        this.unsubscriptions.forEach((unsubscribeFunction) => {
            unsubscribeFunction();
        });
    };
    // TODO: Private?
    update = async (updateData) => {
        await updateDoc(this.docRef, { ...updateData, updatedAt: new Date() });
    };
    updateState = async (updateData) => {
        this.state = merge(this.state, updateData);
        Array.from(this.subscriberCallbacks.values()).forEach((callback) => {
            callback(this.state);
        });
    };
    delete = async () => {
        await deleteDoc(this.docRef);
    };
}
