export const formatListOfThings = (iterable) => {
    return iterable.reduce((acc, char, i) => {
        if (acc === "") {
            return char;
        }
        if (i < iterable.length - 1) {
            return `${acc}, ${char}`;
        }
        return `${acc} and ${char}`;
    }, "");
};
