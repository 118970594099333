import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Button as FlowbiteButton } from "flowbite-react";
const customTheme = {
    base: ` disabled:opacity-50 disabled:cursor-default focus:outline-none transition duration-150 ease-in-out`,
    color: {
        primary: "bg-pink-500 border-pink-500 border text-white enabled:hover:bg-pink-600 enabled:hover:border-pink-600 enabled:active:bg-pink-600 enabled:focus:border-pink-600 focus:shadow-outline-pink ",
        alternate: "text-pink-500 border bg-white border-pink-500 font-semibold enabled:hover:bg-gray-100 enabled:active:bg-gray-200 enabled:focus:border-gray-400 focus:shadow-outline",
        secondary: "bg-gray-600 text-white enabled:hover:bg-gray-700 enabled:active:bg-gray-800 enabled:focus:border-gray-900 focus:shadow-outline-gray ",
        default: "bg-white border border-gray-300 text-gray-700 enabled:hover:bg-gray-100 enabled:active:bg-gray-200 enabled:focus:border-gray-400 focus:shadow-outline ",
    },
    size: {
        default: "py-1 md:py-2 px-4",
        sm: "py-1 md:py-2 px-2",
    },
};
export const Button = ({ children, color = "primary", size = "default", as = "button", className = "", ...rest }) => {
    const isLink = as === "link";
    let linkClasses = color === "primary"
        ? "inline-flex items-center text-pink-500 dark:text-pink-300 p-0 opacity-50 enabled:opacity-100 focus:shadow-none"
        : "inline-flex items-center text-gray-500 dark:text-gray-300 p-0 opacity-50 enabled:opacity-100 enabled:hover:text-pink-500 enabled:active:text-pink-500 enabled:focus:text-pink-500 focus:shadow-none";
    if (className.includes("bg")) {
        linkClasses = `${linkClasses} enabled:hover:bg-pink-600 enabled:active:bg-pink-600 enabled:focus:bg-pink-600 `;
    }
    else {
        linkClasses = `${linkClasses} enabled:hover:text-pink-600 enabled:active:text-pink-600 enabled:focus:text-pink-600 `;
    }
    return isLink ? (_jsx("button", { ...rest, className: `${linkClasses} ${className}`, children: children })) : (_jsx(FlowbiteButton, { theme: customTheme, color: color, size: size, className: `${className || ""}`, pill: true, ...rest, children: children }));
};
