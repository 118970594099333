import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { formatOrderDisplayDate, formatOrderShippingDate, } from "../helpers/date";
import { sortOrderUpdates } from "../helpers/order";
const OrderDetailParagraph = ({ label, value, }) => {
    return (_jsxs("div", { className: "flex flex-col basis-1/2 md:basis-1/3\tmb-12", children: [_jsx("p", { className: "font-semibold", children: label }), _jsx("p", { children: value })] }));
};
export const OrderDetails = ({ order }) => {
    const [mostRecentUpdate] = sortOrderUpdates(order);
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-wrap", children: [_jsx(OrderDetailParagraph, { label: "Order No.", value: order.id }), _jsx(OrderDetailParagraph, { label: "Order Date", value: formatOrderDisplayDate((order?.createdAt?.seconds || 0) * 1000) }), _jsx(OrderDetailParagraph, { label: "Order Status", value: mostRecentUpdate.title }), _jsx(OrderDetailParagraph, { label: "Estimated Ship Date", value: order?.data?.rpi?.estimatedShipDate
                        ? formatOrderShippingDate(order?.data?.rpi?.estimatedShipDate)
                        : "Unknown" }), _jsx(OrderDetailParagraph, { label: "Estimated Arrival Date", value: order?.data?.rpi?.estimatedArrivalDate
                        ? formatOrderShippingDate(order?.data?.rpi?.estimatedArrivalDate)
                        : "Unknown" }), _jsx(OrderDetailParagraph, { label: "Tracking Number", value: order?.data?.rpi?.trackingNumber || "Unknown" })] }) }));
};
