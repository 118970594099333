import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState } from "react";
import { BookModel } from "../../models/book";
import { Model } from "../../models/model";
class MockModel extends Model {
    constructor(id) {
        super(id, "");
    }
    mergePage() {
        return Promise.resolve();
    }
}
const defaultContext = {
    model: null,
};
const BookModelContext = createContext(defaultContext);
export const useBookModelContext = () => useContext(BookModelContext);
export const BookModelContextProvider = ({ bookId, children, }) => {
    const [model, setModel] = useState(null);
    useEffect(() => {
        const bookModel = new BookModel(bookId);
        setModel(bookModel);
        return () => {
            bookModel.unsubscribe();
            setModel(null);
        };
    }, []);
    return (_jsx(BookModelContext.Provider, { value: { model }, children: children }));
};
