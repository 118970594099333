import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator, httpsCallable, } from "firebase/functions";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getPerformance } from "firebase/performance";
import { getAnalytics, isSupported } from "firebase/analytics";
import { env } from "./utils/env";
import { fetchProtected } from "./utils/fetch";
// Configure firebase core
const app = initializeApp(env.firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);
const performance = env.appEnv === "production" ? getPerformance(app) : null;
let analytics;
// Configure callables
const callables = {
    createUser: httpsCallable(functions, "createUser"),
    generateNarrativeIdea: httpsCallable(functions, "generateNarrativeIdea", { timeout: 600 * 1000 }),
    createBookData: httpsCallable(functions, "createBookData", {
        timeout: 600 * 1000,
    }),
    reviseIllustration: httpsCallable(functions, "reviseIllustration", {
        timeout: 300 * 1000,
    }),
    getShippingOptions: httpsCallable(functions, "getShippingOptions"),
    generateBookPdfs: httpsCallable(functions, "generateBookPdfs", {
        timeout: 300 * 1000,
    }),
    createStripeSession: async ({ userIdToken, orderId, searchParams, }) => fetchProtected({
        url: "createStripeSession",
        method: "POST",
        userIdToken,
        body: {
            orderId,
            returnUrl: `/checkout-complete?${searchParams.toString()}`,
        },
    }),
    getStripeSessionStatus: async ({ userIdToken, sessionId, }) => fetchProtected({
        url: `getStripeSessionStatus?session_id=${sessionId}`,
        method: "GET",
        userIdToken,
    }),
};
// Configure analytics
if (env.appEnv === "production") {
    isSupported().then((supported) => {
        if (supported) {
            analytics = getAnalytics(app);
        }
        else {
            analytics = null;
        }
    });
}
// Configure emulators
if (env.appEnv === "test" || env.appEnv === "development") {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");
    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    connectFirestoreEmulator(db, "127.0.0.1", 8080);
    connectStorageEmulator(storage, "127.0.0.1", 9199);
}
// Export entities
export { auth, functions, callables, db, storage, analytics, performance };
