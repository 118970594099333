import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "flowbite-react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { productCostCents, productInfo } from "shared/config/product";
import { OrderModel } from "../models/order";
import { useUserContext } from "./contexts/UserContext";
import { v4 } from "uuid";
export const PurchaseCredits = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUserContext();
    const handleClickBuy = (productId) => async () => {
        if (typeof window === "undefined")
            return;
        if (!user.userId) {
            return;
        }
        const itemId = v4();
        const { id: orderId } = await OrderModel.createOrder({
            userId: user.userId,
            data: {
                items: {
                    [itemId]: {
                        productId,
                        priceCents: productCostCents[productId],
                        quantity: 1,
                    },
                },
            },
        });
        const searchParams = createSearchParams({
            orderId,
            returnUrl: location.pathname,
        });
        navigate(`/checkout?${searchParams.toString()}`);
    };
    return (_jsx("ul", { children: Object.entries(productInfo)
            .filter(([productId]) => productId.startsWith("credits"))
            .map(([productId, { name, description, cost }]) => (_jsx("li", { className: "mb-6 last:mb-0", children: _jsxs("div", { className: "flex flex-row justify-between items-center md:items-end", children: [_jsxs("div", { className: "w-full", children: [_jsx("div", { className: "text-lg lg:text-xl font-bold md:mb-2", children: name }), _jsx("div", { className: "text-gray-700", children: description })] }), _jsxs(Button, { color: "light", className: "h-full text-lg bg-gray-200 text-black self-stretch w-36", onClick: handleClickBuy(productId), children: ["Buy for $", cost] })] }) }, productId))) }));
};
