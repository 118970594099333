import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { LOADING_DATA } from "../../constants";
import { FlipCard } from "../FlipCard";
import { BookLoadingBar } from "./BookLoadingBar";
const IllustrationAndText = ({ index }) => {
    const [text, setText] = useState("");
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        const targetText = LOADING_DATA[index].text + "...";
        if (text.length === targetText.length || !isMounted) {
            return;
        }
        else {
            setTimeout(() => {
                setText(targetText.slice(0, text.length + 1));
            }, 40);
        }
    }, [text, isMounted]);
    useEffect(() => {
        setTimeout(() => {
            setIsMounted(true);
        }, 1000);
    }, []);
    const image = LOADING_DATA[index].image;
    return (_jsxs("div", { className: "text-center h-[32rem] md:h-[40rem] m-auto p-4 flex flex-col items-center justify-center", children: [image && _jsx("img", { src: image, className: "h-[20rem] md:h-[28rem]" }), _jsx("p", { className: `pt-4 block w-full font-semibold text-pink-500`, children: text || "_ " })] }));
};
export const BookLoadingScreen = ({ bookId, className = "", }) => {
    const [frontIndex, setFrontIndex] = useState(0);
    const [backIndex, setBackIndex] = useState(1);
    const [isFlipped, setIsFlipped] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            if (!isFlipped) {
                setTimeout(() => {
                    setFrontIndex((p) => (p + 2 >= LOADING_DATA.length ? 0 : p + 2));
                }, 1000);
            }
            else {
                setTimeout(() => {
                    setBackIndex((p) => (p + 2 >= LOADING_DATA.length ? 1 : p + 2));
                }, 1000);
            }
            setIsFlipped((p) => !p);
        }, 6500);
        return () => {
            clearInterval(interval);
        };
    }, [isFlipped]);
    return (_jsxs("div", { className: `bg-[#ffffffbf] fixed h-full top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center ${className}`, children: [_jsx(FlipCard, { hasBorder: false, 
                // hasBorder
                isFlipped: isFlipped, dimensions: {
                    height: "h-[32rem] md:h-[40rem]",
                    width: "md:w-[600px]",
                }, Front: () => {
                    return _jsx(IllustrationAndText, { index: frontIndex });
                }, Back: () => {
                    return _jsx(IllustrationAndText, { index: backIndex });
                }, duration: 1000 }), _jsx(BookLoadingBar, { bookId: bookId })] }));
};
