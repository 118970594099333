import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useUserContext } from "./contexts/UserContext";
import { TextInput, Label, Spinner } from "flowbite-react";
import { cleanInput } from "../helpers/input";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { ShippingOptions } from "./ShippingOptions";
import { CheckoutForm } from "./Checkout";
import { useOrder } from "../hooks/useOrder";
import { useBook } from "../hooks/useBook";
import { useParams, useNavigate } from "react-router-dom";
import { usePublicImage } from "../hooks/usePublicImage";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { getPageDataFromBook } from "../helpers/page";
import { getBookItemData } from "../helpers/order";
export const OrderBook = () => {
    const navigate = useNavigate();
    // top level data
    const { user } = useUserContext();
    const { orderId } = useParams();
    const { order, hasFetchedOrder, updateOrder } = useOrder(orderId || null);
    // assumption is one book in order right now
    const bookItem = order ? getBookItemData(order) : null;
    const bookId = bookItem?.book?.id || null;
    const { book } = useBook({
        bookId,
        shouldSubscribe: false,
    });
    const currentPageData = getPageDataFromBook(book, "cover");
    // future form data
    const [quantity, setQuantity] = useState(1);
    const [address, setAddress] = useState(order?.data?.rpi?.shippingAddress || {
        name: user.auth?.displayName || "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postal: "",
        country: "US",
    });
    const [selectedShipping, setSelectedShipping] = useState("Economy");
    const [errorFields, setErrorFields] = useState([]);
    const { selectedIllustration } = currentPageData;
    const imageUrl = usePublicImage(selectedIllustration?.url);
    useEffect(() => {
        document.body.style.backgroundColor = "white";
    }, []);
    useEffect(() => {
        if (hasFetchedOrder) {
            if (!order?.id || order?.data?.rpi?.paymentStatus === "PaymentReceived") {
                navigate("/");
            }
            else {
                if (order?.data?.rpi?.shippingAddress) {
                    const { name, ...rest } = order.data.rpi.shippingAddress;
                    setAddress({
                        ...rest,
                        name: name || user.auth?.displayName || "",
                        country: "US",
                    });
                    const election = order?.data?.rpi?.shippingElection || "Economy";
                    setSelectedShipping(`${election[0].toUpperCase()}${election.slice(1)}`);
                }
            }
        }
    }, [hasFetchedOrder, order]);
    const handleUpdateAddress = (newAddress) => {
        setErrorFields((prev) => {
            return prev.filter((field) => {
                if (typeof newAddress[field] !== "undefined") {
                    return !!newAddress[field];
                }
                return field;
            });
        });
        updateOrder({
            [`data.rpi.shippingAddress`]: { ...address, ...newAddress },
            [`data.rpi.shippingElection`]: selectedShipping.toLowerCase(),
            [`data.quantity`]: quantity,
        });
        setAddress((prev) => ({ ...prev, ...newAddress }));
    };
    const handleUpdateInput = (fieldName) => (e) => {
        const value = cleanInput(e.target.value);
        handleUpdateAddress({ [fieldName]: value });
    };
    const handleUpdateShippingSelection = (shippingElection) => {
        setSelectedShipping(shippingElection);
        updateOrder({
            [`data.rpi.shippingElection`]: shippingElection.toLowerCase(),
            [`data.quantity`]: quantity,
        });
    };
    return !bookItem ? (_jsx(Spinner, {})) : (_jsx("div", { className: "max-w-7xl p-8 m-auto", children: _jsxs("div", { className: "md:flex mt-8", children: [_jsxs("div", { className: "w-full md:w-2/4", children: [_jsx("h1", { className: "text-2xl my-8", children: "Order Book" }), _jsxs("div", { className: " mb-12", children: [_jsxs("div", { className: "flex justify-between align-center", children: [_jsxs("div", { className: "flex", children: [_jsx("img", { className: "hidden md:block md:w-20 md:h-20 flex-shrink-0", src: imageUrl || "" }), _jsxs("div", { className: "px-2", children: [_jsx("h3", { children: "8.5x11 Hardcover Storybook" }), _jsx("h2", { className: "text-lg md:text-xl", children: currentPageData.text })] })] }), _jsxs("div", { className: "-mt-1 flex", children: [_jsxs("div", { className: "mx-4 lg:mx-12", children: [_jsx(Label, { htmlFor: "name", value: "Price" }), _jsx("h2", { className: "text-xl", children: "$30.00" })] }), _jsxs("div", { children: [_jsx(Label, { htmlFor: "name", value: "Qty" }), _jsx(TextInput, { type: "number", defaultValue: quantity, onChange: (e) => {
                                                                const value = e.target.value;
                                                                const validValue = isNaN(parseInt(value))
                                                                    ? 1
                                                                    : parseInt(value);
                                                                setQuantity(validValue);
                                                            }, className: "w-12" })] })] })] }), _jsx("div", { className: "mt-2 text-gray-500", children: bookItem?.book?.pdfs?.cover ? (_jsxs("p", { className: "flex items-center text-green-500", children: [_jsx(AiOutlineCheckCircle, { className: "mr-1" }), "Book assets ready"] })) : (_jsxs("p", { children: [_jsx(Spinner, { color: "pink", className: "mr-2" }), "Generating book assets"] })) })] }), _jsx("h2", { className: "text-lg", children: "Shipping Information" }), _jsx("div", { className: "block mt-4", children: _jsx(Label, { htmlFor: "name", value: "Name" }) }), _jsx(TextInput, { id: "name", placeholder: "John Doe", color: errorFields.includes(`name`) ? "failure" : "", onChange: handleUpdateInput("name"), defaultValue: address?.name, required: true }), _jsx(AddressAutocomplete, { errorFields: errorFields, address: address, handleUpdateAddress: handleUpdateAddress }), _jsx("h2", { className: "text-lg mt-12", children: "Shipping Options" }), _jsx(ShippingOptions, { quantity: quantity, address: address, selectedShipping: selectedShipping, setSelectedShipping: handleUpdateShippingSelection })] }), _jsx("div", { className: "w-full md:w-2/4", children: bookItem?.book?.pdfs &&
                        order?.data?.rpi?.shippingAddress?.address1 &&
                        order?.data?.rpi?.shippingAddress?.city &&
                        order?.data?.rpi?.shippingAddress?.state &&
                        order?.data?.rpi?.shippingAddress?.postal ? (_jsx(CheckoutForm, { returnUrl: "", orderId: order.id }, order?.data?.rpi?.shippingElection)) : ("") })] }) }));
};
