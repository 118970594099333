import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as FlowbiteModal } from "flowbite-react";
import React from "react";
import { PurchaseCredits } from "../PurchaseCredits";
import { useGlobalContext } from "./GlobalContext";
const CreditsModalContext = React.createContext({});
export const useCreditsModalContext = () => React.useContext(CreditsModalContext);
export const CreditsModalContextProvider = ({ children, }) => {
    const { showCreditsModal, handleShowCreditsModal } = useGlobalContext();
    return (_jsxs(CreditsModalContext.Provider, { value: {}, children: [children, _jsxs(FlowbiteModal, { show: showCreditsModal, onClose: () => handleShowCreditsModal(false), size: "lg", children: [_jsx(FlowbiteModal.Header, { className: "border-none text-center ", children: _jsx("span", { className: "font-header text-2xl bold", children: "Purchase Tokens" }) }), _jsx(FlowbiteModal.Body, { children: _jsx(PurchaseCredits, {}) })] })] }));
};
