import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "./Button";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
import { IoCartOutline } from "react-icons/io5";
import { IoMdShare } from "react-icons/io";
import { useRef } from "react";
const buttonClassnames = "m-2 text-2xl bg-pink-500 text-white rounded-full w-10 h-10 p-1 flex justify-center transition";
export const BookNavigation = ({ iframeSrc, handleClickNext, handleClickPrev, handleLoadNewImage, handleClickBuy, showClickNext = true, showClickPrev = true, showClickBuy = true, showClickShare = true, isLoadingImage = false, ShareTooltip, }) => {
    const clickRef = useRef(false);
    const handleSafeLoadNewImage = () => {
        if (!handleLoadNewImage || isLoadingImage)
            return;
        handleLoadNewImage();
    };
    const handleDoubleTap = () => {
        if (clickRef.current === true) {
            handleSafeLoadNewImage();
            clickRef.current = false;
        }
        else {
            clickRef.current = true;
            setTimeout(() => {
                clickRef.current = false;
            }, 500);
        }
    };
    return (_jsxs("div", { className: "h-auto relative w-full min-h-vh bg-gray-100 flex flex-col", children: [_jsx("div", { className: "absolute top-0 left-0 right-0  h-full z-10", onDoubleClick: handleSafeLoadNewImage, onTouchStart: handleDoubleTap }), _jsx("iframe", { className: "w-full h-full min-h-[44vh] flex-grow", title: "preview", src: iframeSrc }), (showClickNext || showClickPrev || showClickBuy || showClickShare) && (_jsx("div", { className: "p-4 text-center w-full bg-gray-50 relative z-20", children: _jsxs("div", { className: "flex items-center justify-center", children: [showClickShare && ShareTooltip && (_jsx(ShareTooltip, { children: _jsx(Button, { className: `${buttonClassnames} share-button`, color: "primary", as: "link", title: "Share book", children: _jsx(IoMdShare, {}) }) })), _jsxs("div", { className: "book-nav-buttons flex", children: [showClickPrev && (_jsx(Button, { className: buttonClassnames, disabled: !handleClickPrev, color: "primary", onClick: handleClickPrev || undefined, as: "link", title: "Previous page", children: _jsx(FaArrowLeft, {}) })), showClickNext && (_jsx(Button, { className: buttonClassnames, color: "primary", disabled: !handleClickNext, onClick: handleClickNext || undefined, as: "link", title: "Next page", children: _jsx(FaArrowRight, {}) }))] }), showClickBuy && (_jsx(Button, { className: `${buttonClassnames} buy-button`, disabled: !handleClickBuy, color: "primary", onClick: handleClickBuy || undefined, as: "link", title: "Buy book", children: _jsx(IoCartOutline, {}) }))] }) }))] }));
};
