"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BOOK_STYLE } from "../../../models/book";
import { BookCreationSteps, BookPageKeys, BookStatuses, } from "shared/types/book";
import { useBook } from "../../../hooks/useBook";
import { MakeStoryContext } from "../../contexts/MakeStoryContext";
import { StorySidebar } from "../Sidebar/StorySidebar";
import { useImageContext } from "../../contexts/ImageContext";
import { getNextStep, getPrevStep } from "../../../helpers/page";
import { callables } from "../../../firebase";
import { OrderModel } from "../../../models/order";
import { useErrorContext } from "../../contexts/ErrorContext";
import { AdminBookNavigation } from "../AdminBookNavigation";
import { useBookModelContext } from "../../contexts/BookModelContext";
import { productCostCents } from "shared/config/product";
import { v4 } from "uuid";
export const MakeStoryEditLayer = ({ book, userId, handleShowImageExplainerModal, handleCloseImageExplainerModal, }) => {
    const [currentPageKey, setCurrentPageKey] = useState(BookPageKeys[0]);
    const [cacheKeySuffix, setCacheKeySuffix] = useState(0);
    const navigateTo = useNavigate();
    const { setMessage, handleError } = useErrorContext();
    const { getFetchCacheKey, setFetchCacheKey } = useImageContext(cacheKeySuffix);
    const { model: bookModel } = useBookModelContext();
    const { updateBook } = useBook({
        bookId: book.id,
        bookModel,
        shouldSubscribe: false,
    });
    const regenerateBook = async (regenerateFields) => {
        const bookId = book.id;
        // wipe it clean
        const bookData = {
            style: BOOK_STYLE,
            pages: {
                dedication: book.data.pages.dedication,
            },
            storyType: book.data.storyType,
            characters: regenerateFields.characters,
            synopsis: regenerateFields.synopsis,
            isPublic: book.data?.isPublic || false,
            isFeatured: book.data?.isFeatured || false,
            shouldRhyme: regenerateFields.shouldRhyme,
        };
        setCacheKeySuffix((prev) => prev + 1);
        setCurrentPageKey(BookCreationSteps.Cover);
        await updateBook({ data: bookData, status: BookStatuses.NOT_STARTED });
        try {
            await callables.createBookData({
                bookId,
                options: {
                    forceNarrative: true,
                },
            });
        }
        catch (e) {
            console.error(e);
            handleError(e);
        }
    };
    const navigateBook = (isNext) => () => {
        setCurrentPageKey((prevStep) => {
            const nextStep = isNext ? getNextStep(prevStep) : getPrevStep(prevStep);
            return nextStep === null ? prevStep : nextStep;
        });
    };
    const generateImage = (args) => {
        const { pageKey, nextIllustrationKey, sourceIllustrationKey, feedback = "", } = args;
        const cacheKey = `${book.id}-${pageKey}-${nextIllustrationKey}`;
        if (typeof getFetchCacheKey(cacheKey) !== "undefined") {
            return getFetchCacheKey(cacheKey);
        }
        const fetchIllustrations = callables
            .reviseIllustration({
            bookId: book.id,
            pageKey,
            sourceIllustrationKey,
            feedback,
        })
            .then((data) => {
            if (!data.data.created) {
                setFetchCacheKey(cacheKey, undefined);
            }
            return data;
        })
            .catch((e) => {
            console.error(e);
            setFetchCacheKey(cacheKey, undefined);
            // throw e;
            handleError(e);
        });
        setFetchCacheKey(cacheKey, fetchIllustrations);
        return fetchIllustrations;
    };
    const buyBook = async () => {
        const itemId = v4();
        const { id: orderId } = await OrderModel.createOrder({
            userId,
            data: {
                items: {
                    [itemId]: {
                        productId: "book-hard-8.5x11",
                        priceCents: productCostCents["book-hard-8.5x11"],
                        quantity: 1,
                        book: {
                            id: book.id,
                        },
                    },
                },
            },
        });
        // kick this off now
        callables.generateBookPdfs({
            orderId,
            bookId: book.id,
            itemId,
        });
        setTimeout(() => {
            navigateTo(`/order/${orderId}`);
        }, 500);
    };
    useEffect(() => {
        const handleKeydownNav = (event) => {
            if (!["TEXTAREA", "INPUT"].includes(event.target.tagName) &&
                [`ArrowLeft`, `ArrowRight`].includes(event.key)) {
                const isNext = event.key === `ArrowRight`;
                navigateBook(isNext)();
            }
        };
        document.addEventListener("keydown", handleKeydownNav);
        return () => {
            document.removeEventListener("keydown", handleKeydownNav);
        };
    }, []);
    return (_jsx(MakeStoryContext.Provider, { value: {
            bookId: book.id,
            currentPageKey,
            handlers: {
                regenerateBook,
                updateBook,
                generateImage,
                buyBook,
                navigateBook,
                updateCurrentStep: (newStep) => setCurrentPageKey(newStep),
                handleShowImageExplainerModal,
                handleCloseImageExplainerModal,
            },
        }, children: _jsxs("div", { className: "w-full flex flex-col md:flex-row relative md:h-auto", children: [_jsx(StorySidebar, {}), _jsx(AdminBookNavigation, { bookId: book.id, currentPageKey: currentPageKey }, currentPageKey)] }, "make-story") }));
};
