import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion } from "flowbite-react";
import { ILLUSTRATIONS } from "../helpers/illustration";
import { Link } from "react-router-dom";
const questions = [
    {
        question: "How much does a book cost?",
        answer: 
        // todo  See an example of what the books look like here
        "A book costs $30.00.",
    },
    {
        question: "What size is the book?",
        answer: `The book comes in hardcover, 8.5" x 11"`,
    },
    {
        question: "Will you offer more sizes?",
        answer: `Maybe in the future!`,
    },
    {
        question: "What's the deal with the tokens?",
        answer: "Tokens are used to tweak an image, update a title, or fine-tune the book as you see fit. Every action that reaches out to our magic robots costs a couple of tokens. New users are given 250 tokens to play around with, and more can always be purchased for just a few dollars.",
    },
    {
        question: "Why do my characters look different in every scene?",
        answer: "Right now we're using DALL·E 3, which is amazing and magical, but hasn't quite nailed down image consistency yet. Our goal is to fine-tune our implementation of the model to create stylistically similar images, and continue to improve the character consistency as the underlying technology improves.",
    },
];
export const FAQ = () => {
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "fixed top-0 left-0 right-0 bottom-0 bg-white" }), _jsxs("div", { className: "text-center max-w-[60rem] m-auto p-8 pb-40 relative z-1", children: [_jsx("img", { className: "w-[24rem] my-12 m-auto", src: ILLUSTRATIONS.FAQ }), _jsx("h1", { className: "text-3xl mb-12 leading-10", children: "Frequently Asked Questions" }), _jsx(Accordion, { collapseAll: true, children: questions.map(({ question, answer }) => {
                            return (_jsxs(Accordion.Panel, { children: [_jsx(Accordion.Title, { className: "text-gray-900 text-lg focus:ring-0", children: question }), _jsx(Accordion.Content, { className: "bg-white", children: _jsx("p", { className: "mb-2 text-left", children: answer }) })] }, question));
                        }) }), _jsxs("div", { className: "mt-12", children: ["Have a question that wasn't addressed here? Send us an email at", " ", _jsx(Link, { className: "link", to: "mailto:hello@unicorntales.ai", children: "hello@unicorntales.ai" })] })] })] }));
};
