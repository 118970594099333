import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { callables } from "../../../firebase";
import { BOOK_STYLE, BookModel } from "../../../models/book";
import { BookFormat } from "shared/types/book";
import { useUserContext } from "../../contexts/UserContext";
import { Button } from "../../Button";
import { useEffect, useState } from "react";
import { useErrorContext } from "../../contexts/ErrorContext";
import { BookLoadingScreen } from "../BookLoadingScreen";
import { FaCoins } from "react-icons/fa";
// import { Label, Textarea } from "flowbite-react";
import { formatListOfThings } from "../../../helpers/text";
import { ILLUSTRATIONS } from "../../../helpers/illustration";
import { getMissingCharacters } from "../../../helpers/character";
import { useUserTokens } from "../../../hooks/useUserTokens";
import { AddCharacters } from "./MakeStoryIntro/AddCharacters";
import { ChooseStoryType } from "./MakeStoryIntro/ChooseStoryType";
import { StoryType } from "shared/types/callables";
import { AddStoryDetails } from "./MakeStoryIntro/AddStoryDetails";
/**
 *
 * @returns
          
 */
export const MakeStoryIntro = () => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const [characters, setCharacters] = useState({});
    const [bookId, setBookId] = useState(null);
    const [storyType, setStoryType] = useState(null);
    const [synopsis, setSynopsis] = useState("");
    const [shouldRhyme, setShouldRhyme] = useState(false);
    const [isCreatingBook, setIsCreatingBook] = useState(false);
    const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);
    const [storyIdeas, setStoryIdeas] = useState({
        [StoryType.bedtime]: [],
        [StoryType.silly]: [],
        [StoryType.traditional]: [],
    });
    const { handleError } = useErrorContext();
    const handleCreateBook = async () => {
        if (!user?.userId || storyType === null)
            return;
        let newBookId = bookId;
        if (!newBookId) {
            const bookData = {
                style: BOOK_STYLE,
                storyType,
                pages: {},
                characters,
                synopsis,
                isPublic: false,
                isFeatured: false,
                shouldRhyme,
                isCommunityBook: false,
            };
            const newBookData = {
                format: BookFormat.v1,
                userId: user.userId,
                data: bookData,
            };
            const newBook = await BookModel.createBook(newBookData);
            newBookId = newBook.id;
        }
        setIsCreatingBook(true);
        setBookId(newBookId);
        try {
            await callables.createBookData({
                bookId: newBookId,
                options: {
                    forceNarrative: false,
                    forceTitle: false,
                    forceIllustrations: false,
                },
            });
            navigate(`/make-story/${newBookId}`);
        }
        catch (error) {
            const e = error;
            handleError(e);
            console.error(e);
            // give book generic title, set bookid
            const title = `The Adventures of ${formatListOfThings(Object.keys(characters))}`;
            const book = new BookModel(newBookId);
            await book.update({ [`data.pages.cover.text`]: title });
            // await book.delete();
            setIsCreatingBook(false);
        }
    };
    const handleGenerateIdeas = async (storyType) => {
        if (!user?.userId)
            return;
        setIsGeneratingIdeas(true);
        const { data } = await callables
            .generateNarrativeIdea({
            storyType,
            characters,
        })
            .catch((e) => {
            console.error(e);
            setIsGeneratingIdeas(false);
            return { data: null };
        });
        if (!data)
            return;
        const ideas = data.ideas;
        if (ideas && ideas?.length) {
            setStoryIdeas((prev) => ({
                ...prev,
                [storyType]: [...prev[storyType], ...ideas],
            }));
        }
        setIsGeneratingIdeas(false);
    };
    useEffect(() => {
        setSynopsis("");
    }, [storyType]);
    const hasCharacters = Object.keys(characters || {}).length > 0;
    const { userProfile } = useUserTokens();
    const tokens = userProfile?.balanceCents;
    const hasValidTokens = tokens !== undefined && tokens >= 125;
    const hasValidSynopsis = !!synopsis && getMissingCharacters(characters, synopsis).length === 0;
    if (isCreatingBook && bookId)
        return _jsx(BookLoadingScreen, { bookId: bookId });
    return (_jsxs("div", { className: "md:w-[69rem] p-8 m-auto pb-20", children: [_jsx("img", { src: ILLUSTRATIONS.MAKE_STORY_INTRO, className: "h-[16rem] m-auto" }), _jsx("h1", { className: "text-4xl my-4 pt-4 text-center", children: "Let's Make a Story!" }), _jsx(AddCharacters, { characters: characters, setCharacters: setCharacters }), hasCharacters && (_jsx(ChooseStoryType, { storyType: storyType, setStoryType: setStoryType, setShouldRhyme: setShouldRhyme })), hasCharacters && storyType && (_jsx(AddStoryDetails, { storyType: storyType, synopsis: synopsis, setSynopsis: setSynopsis, characters: characters, isGeneratingIdeas: isGeneratingIdeas, handleGenerateIdeas: handleGenerateIdeas, storyIdeas: storyIdeas[storyType] })), _jsxs(Button, { disabled: !hasValidSynopsis || !hasCharacters || !hasValidTokens, className: "mt-8 mr-0 ml-auto block font-semibold", onClick: handleCreateBook, children: ["Create book ( ", _jsx(FaCoins, { className: "ml-1 mr-1" }), " 125 )"] })] }));
};
