import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Progress } from "flowbite-react";
import { pageOrder } from "shared/types/book";
import { useBook } from "../../hooks/useBook";
const IMAGES_TO_FETCH = pageOrder.filter(({ needsImage }) => needsImage);
const CREATE_BOOK_STEP_COUNT = 2;
// perceived performance, 60 seconds
const CREATE_NARRATIVE_STEP_COUNT = 60;
const CREATE_TITLE_STEP_COUNT = 10;
const IMAGE_STEP_COUNT = 2.5;
const CREATE_IMAGES_STEP_COUNT = IMAGES_TO_FETCH.length * IMAGE_STEP_COUNT;
const TOTAL_STEPS = CREATE_BOOK_STEP_COUNT +
    CREATE_NARRATIVE_STEP_COUNT +
    CREATE_TITLE_STEP_COUNT +
    CREATE_IMAGES_STEP_COUNT;
const getFetchedImages = (book) => {
    return IMAGES_TO_FETCH.map(({ key }) => {
        const illustrations = Object.values((book?.data?.pages?.[key]?.illustrations || {})).map((i) => i?.url);
        return illustrations.filter((i) => i).length > 0;
    }).filter((hasFetched) => hasFetched);
};
const bookHasNarrative = (book) => book?.data?.pages?.["0"]?.text;
const bookHasTitle = (book) => book?.data?.pages?.cover?.text;
const getLoadingText = (book) => {
    const hasBook = !!book?.data;
    const hasNarrative = bookHasNarrative(book);
    const hasTitle = bookHasTitle(book);
    const fetchedImages = getFetchedImages(book);
    if (!hasBook) {
        return "Creating book...";
    }
    else if (!hasNarrative) {
        return "Generating story narrative...";
    }
    else if (!hasTitle) {
        return "Generating title...";
    }
    else {
        return `Creating images (${fetchedImages.length}/${IMAGES_TO_FETCH.length})`;
    }
};
const getLoadingProgress = (book, currentNarrativeSeconds, titleSeconds) => {
    const fetchedImages = getFetchedImages(book);
    const createdBookStep = book?.data ? CREATE_BOOK_STEP_COUNT : 0;
    const createdNarrativeStep = bookHasNarrative(book)
        ? CREATE_NARRATIVE_STEP_COUNT
        : currentNarrativeSeconds || 0;
    const createdTitleStep = bookHasTitle(book)
        ? CREATE_TITLE_STEP_COUNT
        : titleSeconds || 0;
    const stepsCompleted = createdBookStep +
        createdNarrativeStep +
        createdTitleStep +
        fetchedImages.length * IMAGE_STEP_COUNT;
    return Math.round((stepsCompleted / TOTAL_STEPS) * 100);
};
export const BookLoadingBar = ({ bookId }) => {
    const { book } = useBook({ bookId, shouldSubscribe: true });
    const [narrativeSeconds, setNarrativeSeconds] = useState(0);
    const [titleSeconds, setTitleSeconds] = useState(0);
    const [loadingText, setLoadingText] = useState(getLoadingText(book));
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        setLoadingText(getLoadingText(book));
        setProgress(getLoadingProgress(book, narrativeSeconds, titleSeconds));
    }, [book, narrativeSeconds, titleSeconds]);
    useEffect(() => {
        let interval = undefined;
        if (loadingText === "Generating story narrative...") {
            interval = setInterval(() => {
                setNarrativeSeconds((prev) => Math.min((prev || 0) + 1, 59));
            }, 1000);
        }
        else if (loadingText === "Generating title...") {
            interval = setInterval(() => {
                setTitleSeconds((prev) => Math.min((prev || 0) + 1, 9));
            }, 1000);
        }
        if (interval) {
            return () => {
                clearInterval(interval);
            };
        }
    }, [loadingText]);
    return (_jsxs("div", { className: "h-7 w-full mt-14 mb-4 fixed top-0 left-0 right-0 bg-white", children: [typeof progress === "number" && (_jsx(Progress, { progress: progress, className: "transition-all ease-linear\t", size: "md", color: "pink", theme: {
                    base: "bg-gray-100 rounded-l-none transition-all ease-linear	",
                    color: {
                        pink: "bg-pink-500 rounded-l-none transition-all ease-linear	",
                    },
                } })), loadingText && (_jsx("small", { className: "text-pink-500 font-bold pr-4 block w-full text-right", children: loadingText }))] }));
};
