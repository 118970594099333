import { useState, useEffect, useCallback } from "react";
import { unflatten } from "flat";
import get from "lodash.get";
import stringify from "json-stable-stringify";
const replaceKeyPrefix = (obj) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
        return Object.keys(obj).reduce((acc, key) => {
            let str = key;
            while (/key/.test(str)) {
                str = str.replace("key", "");
            }
            return {
                ...acc,
                [str]: replaceKeyPrefix(obj[key]),
            };
        }, {});
    }
    return obj;
};
const replaceNumberObjectKeysWithKeyPrefix = (updateData) => {
    return Object.keys(updateData).reduce((acc, key) => {
        let newKey = key;
        if (/\.(\d+)\.?/.test(key)) {
            newKey = key.replace(/\.(\d+)\.?/g, (match) => {
                return `.key${match.slice(1)}`;
            });
        }
        acc[newKey] = updateData[key];
        return acc;
    }, {});
};
const extract = (doc, reducer) => {
    if (!reducer)
        return doc;
    if (typeof reducer === "string")
        return get(doc, reducer);
    if (typeof reducer === "function")
        return reducer(doc);
};
/** Must be used inside ModelContext */
export const useDocument = ({ documentId, documentModel, reducer, shouldSubscribe = true, }) => {
    const [_document, setDocument] = useState(null);
    const [hasFetchedDocument, setHasFetchedDocument] = useState(false);
    const compareAndSetDocument = (nextValue) => {
        setDocument((prev) => {
            if (stringify(prev) === stringify(nextValue)) {
                return prev;
            }
            return nextValue;
        });
    };
    const getInitialDocument = async () => {
        if (typeof documentId !== "string" || !documentId || !documentModel) {
            return;
        }
        try {
            const documentData = await documentModel.get();
            compareAndSetDocument(extract(documentData, reducer));
        }
        catch (e) {
            console.error(e);
            setDocument(null);
        }
        setHasFetchedDocument(true);
    };
    useEffect(() => {
        if (typeof documentId !== "string" || !documentId || !documentModel) {
            return;
        }
        getInitialDocument();
        let unsubscribe;
        if (shouldSubscribe && documentModel) {
            unsubscribe = documentModel.subscribe((data) => {
                compareAndSetDocument(extract(data, reducer));
                setHasFetchedDocument(true);
            });
        }
        return () => {
            setHasFetchedDocument(false);
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [documentId, documentModel, reducer]);
    const updateDocument = useCallback(async (newDocumentData) => {
        if (typeof documentId !== "string" || !documentId || !documentModel) {
            return;
        }
        updateDocumentState(newDocumentData);
        await documentModel.update(newDocumentData);
    }, [documentId, documentModel]);
    const updateDocumentState = useCallback((newDocumentData) => {
        if (typeof documentId !== "string" || !documentId || !documentModel) {
            return;
        }
        // `unflatten` lib interprets number key names (eg "0") as array indices
        // to get it to work, we replace our page keys / illustration keys with `key`
        const updateDataWithNonIntegerKeyNames = replaceNumberObjectKeysWithKeyPrefix(newDocumentData);
        // feed this to `unflatten` with will take {[`data.pages.key0.text`]: "cat in the hat"}
        // and turn it into {data: {pages: {key0: {text: "cat"}}}}
        const expandedObjectWithNonIntegerKeyNames = unflatten(updateDataWithNonIntegerKeyNames);
        // now that the object is expanded properly, we replace `key0` with `0`
        // to make it {data: {pages: {0: {text: "cat"}}}}
        const expandedObjectWithIntegerKeyNames = replaceKeyPrefix(expandedObjectWithNonIntegerKeyNames);
        documentModel.updateState(expandedObjectWithIntegerKeyNames);
    }, [documentId, documentModel]);
    return {
        document: _document,
        hasFetchedDocument,
        updateDocument,
        updateDocumentState,
    };
};
