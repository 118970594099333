"use client";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Navbar, Dropdown } from "flowbite-react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCoins } from "react-icons/fa";
import { useUserContext } from "./contexts/UserContext";
import { useGlobalContext } from "./contexts/GlobalContext";
import { linkTheme } from "./Header";
import { useUserTokens } from "../hooks/useUserTokens";
export const UserProfileLinks = () => {
    const { user, handleSignOut } = useUserContext();
    const { handleShowCreditsModal } = useGlobalContext();
    const location = useLocation();
    const navigate = useNavigate();
    const { userProfile } = useUserTokens();
    const tokens = userProfile?.balanceCents;
    const isProfilePath = location.pathname.includes("/profile");
    const isAccountPage = isProfilePath && !location.hash;
    const isMyBooksPage = isProfilePath && location.hash === "#books";
    const isMyOrdersPage = isProfilePath && location.hash === "#orders";
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "hidden md:block", children: _jsxs(Dropdown, { arrowIcon: false, inline: true, label: _jsxs("div", { className: `user-credits rounded-full flex items-center hover:text-pink-500 transition ${isAccountPage ? "text-pink-500" : ""}`, children: [(user.auth?.displayName || "").split(" ")[0], " (", _jsx(FaCoins, { className: "mx-1" }), Math.floor(tokens || 0), " )"] }), children: [_jsx(Dropdown.Item, { onClick: () => navigate("/profile"), children: _jsx(Navbar.Link, { active: isAccountPage, theme: linkTheme, href: "/profile", children: "Profile" }) }), _jsx(Dropdown.Item, { onClick: () => navigate("/profile#books"), children: _jsx(Navbar.Link, { active: isMyBooksPage, theme: linkTheme, href: "/profile#books", children: "My Books" }) }), _jsx(Dropdown.Item, { onClick: () => navigate("/profile#orders"), children: _jsx(Navbar.Link, { active: isMyOrdersPage, theme: linkTheme, href: "/profile#orders", children: "My Orders" }) }), _jsx(Dropdown.Item, { onClick: () => handleShowCreditsModal(true), children: "Purchase tokens" }), _jsx(Dropdown.Item, { onClick: handleSignOut, children: "Log out" })] }) }), _jsxs("div", { className: "block md:hidden", children: [_jsx("div", { className: "md:self-center", children: _jsx(Navbar.Link, { active: isAccountPage, theme: linkTheme, href: "/profile", children: "Profile" }) }), _jsx("div", { className: "md:self-center", children: _jsx(Navbar.Link, { active: isMyBooksPage, theme: linkTheme, href: "/profile#books", children: "My Books" }) }), _jsx("div", { className: "md:self-center", children: _jsx(Navbar.Link, { active: isMyOrdersPage, theme: linkTheme, href: "/profile#orders", children: "My Orders" }) }), _jsx("div", { className: "md:self-center", children: _jsx(Navbar.Link, { theme: linkTheme, 
                            // href={"/profile"}
                            onClick: () => handleShowCreditsModal(true), children: "Purchase tokens" }) })] })] }));
};
