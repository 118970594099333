"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Label, TextInput, Textarea } from "flowbite-react";
import { Button } from "../Button";
import { cleanInput } from "../../helpers/input";
import { CHARACTER_CHAR_LIMIT } from "../../constants";
export const CharacterForm = ({ character, handleUpdateCharacter, handleDeleteCharacter, buttonText = "Save", }) => {
    const [name, setName] = useState(character?.name || "");
    const [description, setDescription] = useState(character?.description || "");
    const [appearance, setAppearance] = useState(character?.appearance || "");
    const [errorFields, setErrorFields] = useState([]);
    // useEffect(() => {
    //   setName(character?.name || "");
    //   setDescription(character?.description || "");
    // }, [character]);
    const handleClickUpdateCharacter = useCallback(() => {
        const newErrorFields = [];
        if (!name) {
            newErrorFields.push(`characterName`);
        }
        if (!description) {
            newErrorFields.push(`characterDescription`);
        }
        if (!appearance) {
            newErrorFields.push(`characterAppearance`);
        }
        if (newErrorFields.length) {
            setErrorFields(newErrorFields);
        }
        else {
            handleUpdateCharacter({ name, appearance, description });
        }
    }, [name, appearance, description]);
    return (_jsxs("div", { className: "w-full text-left", children: [_jsx("div", { className: "block", children: _jsx(Label, { className: " text-[.75rem]", htmlFor: "characterName", value: "Character Name" }) }), _jsx(TextInput, { id: "characterName", placeholder: "e.g. Penelope", autoFocus: true, color: errorFields.includes(`characterName`) ? "failure" : "", onChange: (e) => {
                    const newName = cleanInput(e.target.value);
                    if (!!newName && errorFields.includes(`characterName`)) {
                        setErrorFields((prev) => prev.filter((n) => n !== `characterName`));
                    }
                    setName(newName);
                }, defaultValue: name, required: true }), _jsxs("div", { className: "mt-2 mb-1 font-xxs flex justify-between", children: [_jsx(Label, { className: " text-[.75rem]", htmlFor: "characterDescription", value: `Character Description ` }), _jsxs("small", { children: ["(", description.length, "/", CHARACTER_CHAR_LIMIT, " chars)"] })] }), _jsx(Textarea, { id: "characterDescription", className: "border-gray-200", maxLength: CHARACTER_CHAR_LIMIT, placeholder: "e.g. A clever and energetic little girl", color: errorFields.includes(`characterDescription`) ? "failure" : "", onChange: (e) => {
                    const newDescription = cleanInput(e.target.value);
                    if (!!newDescription &&
                        errorFields.includes(`characterDescription`)) {
                        setErrorFields((prev) => prev.filter((n) => n !== `characterDescription`));
                    }
                    setDescription(newDescription);
                }, defaultValue: description, required: true }), _jsxs("div", { className: "mt-2 mb-1 flex justify-between", children: [_jsx(Label, { className: " text-[.75rem]", htmlFor: "characterAppearance", value: "Character Appearance" }), _jsxs("small", { children: ["(", appearance.length, "/", CHARACTER_CHAR_LIMIT, " chars)"] })] }), _jsx(Textarea, { id: "characterAppearance", className: "border-gray-200", maxLength: CHARACTER_CHAR_LIMIT, placeholder: "e.g. A little girl with brown hair and blue eyes", color: errorFields.includes(`characterAppearance`) ? "failure" : "", onChange: (e) => {
                    const newAppearance = cleanInput(e.target.value);
                    if (!!newAppearance && errorFields.includes(`characterAppearance`)) {
                        setErrorFields((prev) => prev.filter((n) => n !== `characterAppearance`));
                    }
                    setAppearance(newAppearance);
                }, defaultValue: appearance, required: true }), _jsxs("div", { className: "w-full flex", children: [_jsx(Button, { className: "w-full mt-4 mr-1", onClick: handleDeleteCharacter, color: "alternate", children: "Delete" }), _jsx(Button, { className: "w-full mt-4 ml-1", onClick: handleClickUpdateCharacter, children: buttonText })] })] }));
};
