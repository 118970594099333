import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";
import { env } from "./utils/env";
if (env.appEnv === "production") {
    LogRocket.init("gvcpbx/unicorntales", { mergeIframes: true });
    setupLogRocketReact(LogRocket);
    LogRocket.getSessionURL(function (sessionURL) {
        if (analytics) {
            logEvent(analytics, "event", {
                eventCategory: "LogRocket",
                eventAction: sessionURL,
            });
        }
    });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
