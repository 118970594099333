import { useMemo } from "react";
import { OrderModel } from "../models/order";
import { useDocument } from "./useDocument";
export const useOrder = (orderId) => {
    const orderModel = useMemo(() => {
        return orderId ? new OrderModel(orderId) : null;
    }, [orderId]);
    const { document: order, hasFetchedDocument: hasFetchedOrder, updateDocument: updateOrder, } = useDocument({
        documentId: orderId,
        documentModel: orderModel,
        shouldSubscribe: true,
    });
    return {
        order,
        hasFetchedOrder,
        updateOrder,
    };
};
