import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useUserContext } from "./contexts/UserContext";
import { useQueryParams } from "../hooks/useQueryParams";
import { Link } from "react-router-dom";
import { callables } from "../firebase";
import { useOrder } from "../hooks/useOrder";
import { OrderDetails } from "./OrderDetails";
import { ILLUSTRATIONS } from "../helpers/illustration";
import { getBookItemData, getCreditItemData } from "../helpers/order";
export const CheckoutComplete = () => {
    const navigate = useNavigate();
    const { session_id: sessionId, returnUrl, orderId } = useQueryParams();
    const [status, setStatus] = useState(null);
    const { user } = useUserContext();
    const { order } = useOrder(orderId);
    useEffect(() => {
        if (!sessionId)
            return;
        if (!user.userIdToken)
            return;
        callables
            .getStripeSessionStatus({ userIdToken: user.userIdToken, sessionId })
            .then((res) => res.json())
            .then((data) => {
            // if (data.status === "complete" && returnUrl) {
            //   navigate(returnUrl);
            // }
            setStatus(data.status);
        });
    }, [sessionId, user.userIdToken]);
    if (status === "open") {
        return _jsx(Navigate, { to: "/checkout" });
    }
    console.log(order);
    // assumes 1 book item
    const productId = order
        ? getCreditItemData(order)?.productId || getBookItemData(order)?.productId
        : null;
    const isCredits = productId && productId?.includes("credits");
    return (_jsxs("div", { className: "md:w-[48rem] mt-12 p-8 m-auto text-center", children: [productId ? (_jsx("img", { src: isCredits
                    ? ILLUSTRATIONS.TOKENS_PURCHASE
                    : ILLUSTRATIONS.BOOK_PURCHASE, className: "h-[300px] m-auto mb-4" })) : (_jsx("div", { className: "h-[300px] m-auto mb-4" })), _jsx("h1", { className: "text-4xl mb-4", children: "Success!" }), _jsxs("p", { children: ["Thank you for your order! Your payment has been received", isCredits ? (_jsxs(_Fragment, { children: [" ", "and your accound has been credited", " ", _jsxs("span", { className: "font-bold", children: [productId.split("-")[1], " tokens"] })] })) : (""), ". View your order details any time on your", " ", _jsx(Link, { to: "/profile", className: "link", children: "profile page" })] }), returnUrl && (_jsx(Link, { to: returnUrl, className: "link my-4 block", children: "Take me back to where I was" })), !isCredits && order && (_jsxs("div", { children: [_jsx("h2", { className: "text-2xl text-center mt-12 font-bold", children: "Order Details" }), _jsx("hr", { className: "mt-4" }), _jsx("div", { className: "py-4 mt-4 text-center", children: _jsx(OrderDetails, { order: order }) })] }))] }));
};
