export const sortOrderUpdates = (order) => {
    if (!order)
        return [];
    // inject payment received and date created as an update
    const dateCreated = order.createdAt
        ?.seconds * 1000;
    const paymentReceived = {
        title: order?.data?.rpi?.paymentStatus ?? "Received",
        message: "",
    };
    const shimmedUpdates = {
        ...order?.data?.rpi?.fulfillmentStatus,
        [`${new Date(dateCreated)}`]: paymentReceived,
    };
    return Object.keys(shimmedUpdates)
        .sort((a, b) => {
        return new Date(b).getTime() - new Date(a).getTime();
    })
        .reduce((acc, key) => {
        return [...acc, { ...shimmedUpdates[key], timestamp: key }];
    }, []);
};
/**
 * Right now assumes 1 book item in the entire order
 * This can be updated and expanded to take in a book id and return
 * the items associated with that book id
 */
export const getBookItemData = (order) => {
    return Object.values(order?.data?.items || []).find((i) => i?.book?.id);
};
export const getCreditItemData = (order) => {
    return Object.values(order?.data?.items || []).find((i) => !i?.book?.id);
};
