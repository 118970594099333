import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "../Button";
import { RiPencilLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
export const CharacterCard = ({ name, description, appearance, handleClickEditCharacter, handleDeleteCharacter, }) => {
    return (_jsx("div", { className: "w-full p-4 sm:p-2 bg-white mb-2 shadow mr-auto rounded-lg ", children: _jsxs("div", { className: "flex flex-col sm:flex-row justify-between", children: [_jsxs("div", { className: "flex flex-col sm:flex-row items-center", children: [_jsx("h1", { className: "text-xl mx-2 my-2 sm:my-0", children: name }), _jsx("p", { children: description })] }), _jsxs("div", { className: "flex-wrap sm:flex-nowrap flex items-center justify-center mt-4 sm:mt-0", children: [_jsxs(Button, { className: "bg-gray-50 mr-2 w-full sm:w-auto mb-2 sm:my-0", color: "default", onClick: () => handleClickEditCharacter(name), children: [_jsx(RiPencilLine, { className: "mr-1" }), " Edit"] }), _jsxs(Button
                        // className="absolute top-0 right-0 text-xl m-2"
                        , { 
                            // className="absolute top-0 right-0 text-xl m-2"
                            className: "bg-gray-50 w-full sm:w-auto mb-2 sm:my-0", color: "default", onClick: handleDeleteCharacter, children: [_jsx(IoClose, { className: "mr-1" }), " Delete"] })] })] }) }));
};
