"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Label, Textarea, Button as NavbarButton } from "flowbite-react";
import { Button } from "../Button";
import { FaCoins, FaPlus } from "react-icons/fa";
import { cleanInput } from "../../helpers/input";
import MakeCharacterModal from "./Modals/MakeCharacterModal";
import { useMakeStoryContext } from "../contexts/MakeStoryContext";
import { getAreAnyImagesLoading } from "../../helpers/page";
import { IoClose } from "react-icons/io5";
import { Checkbox } from "flowbite-react";
import { useBookModelContext } from "../contexts/BookModelContext";
import { useDocument } from "../../hooks/useDocument";
import { formatListOfThings } from "../../helpers/text";
import { getMissingCharacters } from "../../helpers/character";
import { StoryType } from "shared/types/callables";
export const CreateCharactersAndSynopsis = () => {
    const { bookId, handlers } = useMakeStoryContext();
    const [isCharacterModalOpen, setIsCharacterModalOpen] = useState(false);
    const [characterToEdit, setCharacterToEdit] = useState(null);
    const [synopsisError, setSynopsisError] = useState("");
    //const [shouldRhyme, setShouldRhyme] = useState(false);
    const { model: bookModel } = useBookModelContext();
    const { document: book } = useDocument({
        documentId: bookId,
        shouldSubscribe: true,
        documentModel: bookModel,
        reducer: (book) => {
            const { synopsis, characters, shouldRhyme, storyType } = book?.data || {};
            const areAnyImagesLoading = getAreAnyImagesLoading(book);
            return {
                synopsis,
                characters,
                areAnyImagesLoading,
                shouldRhyme,
                storyType,
            };
        },
    });
    const { synopsis = "", characters = {}, areAnyImagesLoading = false, shouldRhyme = false, storyType, } = book || {};
    const handleChangeStoryType = (type) => async () => {
        await handlers.updateBook({
            ["data.storyType"]: type,
        });
    };
    useEffect(() => {
        const missingCharacters = getMissingCharacters(characters, synopsis);
        if (missingCharacters.length) {
            const isOrAre = missingCharacters.length === 1 ? "is" : "are";
            setSynopsisError(`${formatListOfThings(missingCharacters)} ${isOrAre} missing from your synopsis. Please make sure to mention all characters by name, or delete them if not in the story.`);
        }
        else {
            setSynopsisError("");
        }
    }, [synopsis, Object.keys(characters)]);
    const defaultClass = "w-1/3 enabled:active:text-white enabled:focus:text-white enabled:hover:bg-pink-500 enabled:hover:text-white";
    const activeClass = "bg-pink-500 text-white";
    return (_jsxs("div", { className: "", children: [_jsx("p", { className: "font-bold mb-2", children: "Characters" }), Object.entries(characters).map(([name, { appearance, description }]) => {
                return (_jsxs("div", { className: "flex items-center", children: [_jsxs("div", { className: "w-full leading-5 border text-gray-500 cursor-pointer bg-white p-2 px-2 rounded-md shadow-sm text-xxs my-2", onClick: () => {
                                setCharacterToEdit({ name, appearance, description });
                                setIsCharacterModalOpen(true);
                            }, children: [_jsx("span", { className: "font-bold font-header text-black uppercase pr-2", children: name }), _jsx("small", { className: "italic", children: description })] }), _jsx(Button, { className: "text-xl font-bold ml-2", as: "link", onClick: async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const newCharacters = { ...characters };
                                delete newCharacters[name];
                                await handlers.updateBook({
                                    ["data.characters"]: newCharacters,
                                });
                            }, children: _jsx(IoClose, {}) })] }, name));
            }), _jsx("div", { className: "my-4" }), _jsxs(Button, { as: "link", className: "text-sm block mb-3", onClick: () => {
                    setCharacterToEdit(null);
                    setIsCharacterModalOpen(true);
                }, children: [_jsx(FaPlus, { className: "mr-2" }), "Add Character"] }), _jsx(MakeCharacterModal, { isOpen: isCharacterModalOpen, character: characterToEdit, handleCloseModal: () => {
                    setIsCharacterModalOpen(false);
                    setCharacterToEdit(null);
                }, handleDeleteCharacters: async () => {
                    if (!characterToEdit?.name)
                        return;
                    const newChars = {
                        ...characters,
                    };
                    delete newChars[characterToEdit.name];
                    await handlers.updateBook({
                        [`data.characters`]: {
                            ...newChars,
                        },
                    });
                }, handleUpdateCharacters: async (character) => {
                    const newChars = {
                        ...characters,
                    };
                    console.log(character);
                    if (characterToEdit?.name &&
                        characterToEdit?.name !== character?.name) {
                        delete newChars[characterToEdit?.name];
                    }
                    await handlers.updateBook({
                        [`data.characters`]: {
                            ...newChars,
                            [character?.name]: character,
                        },
                    });
                } }), _jsx("p", { className: "font-bold mt-2", children: "Synopsis" }), _jsx("small", { children: "What is your book about?" }), _jsx(Textarea, { defaultValue: synopsis, onChange: (e) => {
                    handlers.updateBook({
                        [`data.synopsis`]: cleanInput(e.target.value),
                    });
                }, className: "mb-1 mt-2 h-24", maxLength: 1500 }), _jsxs("small", { className: "mb-3 block", children: ["(", synopsis.length, "/650)"] }), synopsis && synopsisError && (_jsx("p", { className: "text-pink-500 text-sm my-2 font-semibold", children: synopsisError })), _jsxs("div", { children: [_jsx(Checkbox, { className: "m-1 text-pink-500", id: "rhyme", checked: shouldRhyme, onChange: async (event) => {
                            const isChecked = event.target.checked;
                            await handlers.updateBook({
                                ["data.shouldRhyme"]: isChecked,
                            });
                        } }), " ", _jsx(Label, { htmlFor: "rhyme", className: "text-sm", children: "Story should rhyme" })] }), _jsxs(NavbarButton.Group, { color: "pink", className: `max-w-full mt-4`, children: [_jsx(NavbarButton, { className: `${defaultClass} ${storyType === "bedtime" && activeClass}`, color: "gray", onClick: handleChangeStoryType("bedtime"), children: "Bedtime story" }), _jsx(NavbarButton, { className: `${defaultClass} ${storyType === "silly" && activeClass}`, color: "gray", onClick: handleChangeStoryType("silly"), children: "Silly story" }), _jsx(NavbarButton, { className: `${defaultClass} ${storyType === StoryType.traditional && activeClass}`, color: "gray", onClick: handleChangeStoryType(StoryType.traditional), children: "Traditional story" })] }), _jsx(Button, { className: "w-full text-sm mt-3", onClick: () => {
                    handlers.regenerateBook({ synopsis, characters, shouldRhyme });
                }, disabled: areAnyImagesLoading || !!synopsisError, children: bookId ? (_jsxs("span", { className: "flex items-center justify-center", children: ["Regenerate book ( ", _jsx(FaCoins, { className: "ml-1 mr-1" }), " 125 )"] })) : ("Generate Book") })] }));
};
