import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout, } from "@stripe/react-stripe-js";
import { createSearchParams } from "react-router-dom";
import { env } from "../utils/env";
import { useUserContext } from "./contexts/UserContext";
import { useQueryParams } from "../hooks/useQueryParams";
import { Spinner } from "flowbite-react";
import { callables } from "../firebase";
import { useOrder } from "../hooks/useOrder";
const stripePromise = loadStripe(env.stripePublicKey);
export const CheckoutFormPage = () => {
    const { orderId, returnUrl } = useQueryParams();
    return _jsx(CheckoutForm, { orderId: orderId, returnUrl: returnUrl });
};
export const CheckoutForm = ({ orderId, returnUrl, }) => {
    const [clientSecret, setClientSecret] = useState("");
    const { user } = useUserContext();
    const { order, hasFetchedOrder } = useOrder(orderId);
    console.log(order);
    useEffect(() => {
        if (!user.userIdToken || !order?.id)
            return;
        const searchParams = createSearchParams({
            ...(orderId ? { orderId } : {}),
            returnUrl,
        });
        callables
            .createStripeSession({
            userIdToken: user.userIdToken,
            orderId,
            searchParams,
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret))
            .catch(console.error);
    }, [hasFetchedOrder]);
    return (_jsx("div", { id: "checkout", className: "p-2", children: clientSecret ? (_jsx(EmbeddedCheckoutProvider, { stripe: stripePromise, options: { clientSecret }, children: _jsx(EmbeddedCheckout, {}) })) : (_jsx("div", { className: "h-screen bg-white opacity-70 z-10 flex items-center justify-center m-auto", children: _jsx(Spinner, { color: "pink", size: "xl" }) })) }));
};
