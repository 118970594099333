"use client";
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Modal } from "flowbite-react";
import { CharacterForm } from "../CharacterForm";
export default function MakeCharacterModal({ isOpen, character, handleCloseModal, handleUpdateCharacters, handleDeleteCharacters, }) {
    return (_jsxs(Modal, { show: isOpen, onClose: handleCloseModal, children: [_jsx(Modal.Header, { children: _jsxs("span", { className: "font-header bold", children: [character ? "Edit" : "Add", " Character"] }) }), _jsx(Modal.Body, { children: _jsx(CharacterForm, { character: character, handleUpdateCharacter: (character) => {
                        handleUpdateCharacters(character);
                        handleCloseModal();
                    }, handleDeleteCharacter: handleDeleteCharacters, buttonText: "Save" }, `${JSON.stringify(character)}-${isOpen}`) })] }));
}
