import { BookPageKeys, CurrentStepToPage, pageOrder, } from "shared/types/book";
export const getNextStep = (pageKey) => {
    const index = BookPageKeys.findIndex((key) => pageKey === key);
    return index > -1 && index < BookPageKeys.length - 1
        ? BookPageKeys[index + 1]
        : null;
};
export const getPrevStep = (pageKey) => {
    const index = BookPageKeys.findIndex((key) => pageKey === key);
    return index > 0 ? BookPageKeys[index - 1] : null;
};
export const getPageData = (pageData, pageKey) => {
    const pageTemplateKey = !isNaN(Number(pageKey))
        ? "page"
        : pageKey?.toLowerCase();
    if (!pageData)
        return { pageKey, pageTemplateKey };
    const data = {
        pageTemplateKey,
        pageKey,
        error: pageData.error,
        text: pageData.text,
        imageDescription: pageData.imageDescription,
        enhancedImageDescription: pageData.enhancedImageDescription,
        illustrations: pageData.illustrations,
        selectedIllustrationKey: pageData.selectedIllustrationKey,
        selectedIllustration: pageData.illustrations?.[pageData?.selectedIllustrationKey ?? "0"],
        needsImage: !!pageOrder.find((k) => k.key === pageKey)
            ?.needsImage,
        needsText: !!pageOrder.find((k) => k.key === pageKey)?.needsText,
    };
    return data;
};
export const getPageDataFromBook = (book, _pageKey) => {
    // todo clean this up
    const pageKey = CurrentStepToPage[_pageKey] || _pageKey;
    const pageTemplateKey = !isNaN(Number(pageKey)) ? "page" : pageKey;
    const pageData = book?.data?.pages[pageKey];
    if (!pageData)
        return { pageKey, pageTemplateKey };
    const data = {
        pageTemplateKey,
        pageKey,
        error: pageData.error,
        text: pageData.text,
        imageDescription: pageData.imageDescription,
        enhancedImageDescription: pageData.enhancedImageDescription,
        illustrations: pageData.illustrations,
        selectedIllustrationKey: pageData.selectedIllustrationKey,
        selectedIllustration: pageData.illustrations?.[pageData?.selectedIllustrationKey ?? "0"],
        needsImage: !!pageOrder.find((k) => k.key === pageKey)
            ?.needsImage,
        needsText: !!pageOrder.find((k) => k.key === pageKey)?.needsText,
    };
    return data;
};
export const getNextIllustrationKey = (illustrations) => {
    return (Math.max(...Object.keys(illustrations).map(Number), -1) + 1).toString();
};
export const getAreAnyImagesLoading = (book) => {
    return (Object.values(book.data.pages || {})
        .flatMap((page) => {
        return Object.values(page.illustrations || {}).map(({ url }) => url);
    }, [])
        .filter((url) => url === "").length > 0);
};
