import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { ViewStory } from "./components/ViewStory";
import { CheckoutFormPage } from "./components/Checkout";
import { CheckoutComplete } from "./components/CheckoutComplete";
import { MakeStory } from "./components/Admin/MakeStory/MakeStory";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login/SignUpLoginPage";
import { ImageContextProvider } from "./components/contexts/ImageContext";
import { Profile } from "./components/Profile";
import { UserContextProvider } from "./components/contexts/UserContext";
import { OrderBook } from "./components/OrderBook";
import { ErrorContextProvider } from "./components/contexts/ErrorContext";
import { ViewAll } from "./components/ViewAll";
import { CreditsModalContextProvider } from "./components/contexts/CreditsModalContext";
import { GlobalContextProvider } from "./components/contexts/GlobalContext";
import { PageNotFound } from "./components/PageNotFound";
import { FAQ } from "./components/FAQ";
import { MakeStoryIntro } from "./components/Admin/MakeStory/MakeStoryIntro";
import { Footer } from "./components/Footer";
import { Terms_Of_Service } from "./components/TOS";
import { PrivacyPolicy } from "./components/PrivacyPolicyPage";
const Contexts = ({ children }) => {
    return (_jsx(GlobalContextProvider, { children: _jsx(ErrorContextProvider, { children: _jsx(UserContextProvider, { children: _jsx(CreditsModalContextProvider, { children: _jsx(ImageContextProvider, { children: children }) }) }) }) }));
};
const Page = ({ Component }) => {
    return (_jsxs(Contexts, { children: [_jsx(Header, {}), _jsx("div", { className: "min-h-vh", children: _jsx(Component, {}) }), _jsx(Footer, {})] }));
};
const PrivatePage = ({ Component }) => {
    return (_jsx(Contexts, { children: _jsxs(PrivateRoute, { children: [_jsx(Header, {}), _jsx("div", { className: "min-h-vh", children: _jsx(Component, {}) }), _jsx(Footer, {})] }) }));
};
/** Public */
const HomePage = () => _jsx(Page, { Component: Home });
const ViewAllPublic = () => _jsx(Page, { Component: ViewAll });
const ViewStoryPage = () => _jsx(Page, { Component: ViewStory });
const LoginPage = () => _jsx(Page, { Component: Login });
const NotFoundPage = () => _jsx(Page, { Component: PageNotFound });
const FAQPage = () => _jsx(Page, { Component: FAQ });
const TOSPage = () => _jsx(Page, { Component: Terms_Of_Service });
const PrivacyPolicyPage = () => _jsx(Page, { Component: PrivacyPolicy });
/** Private */
const MakeStoryIntroPage = () => _jsx(PrivatePage, { Component: MakeStoryIntro });
const MakeStoryPage = () => _jsx(PrivatePage, { Component: MakeStory });
const ProfilePage = () => _jsx(PrivatePage, { Component: Profile });
const OrderPage = () => _jsx(PrivatePage, { Component: OrderBook });
const CheckoutPage = () => _jsx(PrivatePage, { Component: CheckoutFormPage });
const CheckoutCompletePage = () => _jsx(PrivatePage, { Component: CheckoutComplete });
function App() {
    return (_jsx(Router, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", Component: HomePage }), _jsx(Route, { path: "/make-story", Component: MakeStoryIntroPage }), _jsx(Route, { path: "/make-story/:bookId", Component: MakeStoryPage }), _jsx(Route, { path: "/view", Component: ViewAllPublic }), _jsx(Route, { path: "/view/:bookId", Component: ViewStoryPage }), _jsx(Route, { path: "/order/:orderId", Component: OrderPage }), _jsx(Route, { path: "/checkout", Component: CheckoutPage }), _jsx(Route, { path: "/checkout-complete", Component: CheckoutCompletePage }), _jsx(Route, { path: "/login", Component: LoginPage }), _jsx(Route, { path: "/profile", Component: ProfilePage }), _jsx(Route, { path: "/faq", Component: FAQPage }), _jsx(Route, { path: "/terms-of-service", Component: TOSPage }), _jsx(Route, { path: "/privacy-policy", Component: PrivacyPolicyPage }), _jsx(Route, { path: "*", Component: NotFoundPage })] }) }));
}
export default App;
