import { NUM_BOOK_PAGES } from "../config/book";
export var BookCreationSteps;
(function (BookCreationSteps) {
    BookCreationSteps["CharactersAndSynopsis"] = "CharactersAndSynopsis";
    BookCreationSteps["Cover"] = "Cover";
    BookCreationSteps["Dedication"] = "Dedication";
    BookCreationSteps["Page0"] = "Page0";
    BookCreationSteps["Page1"] = "Page1";
    BookCreationSteps["Page2"] = "Page2";
    BookCreationSteps["Page3"] = "Page3";
    BookCreationSteps["Page4"] = "Page4";
    BookCreationSteps["Page5"] = "Page5";
    BookCreationSteps["Page6"] = "Page6";
    BookCreationSteps["Page7"] = "Page7";
    BookCreationSteps["Page8"] = "Page8";
    BookCreationSteps["Page9"] = "Page9";
    BookCreationSteps["Page10"] = "Page10";
    BookCreationSteps["Page11"] = "Page11";
    BookCreationSteps["Page12"] = "Page12";
    BookCreationSteps["Page13"] = "Page13";
    BookCreationSteps["Page14"] = "Page14";
    BookCreationSteps["Branding"] = "Branding";
    BookCreationSteps["End"] = "End";
})(BookCreationSteps || (BookCreationSteps = {}));
export var CurrentStepToPage;
(function (CurrentStepToPage) {
    CurrentStepToPage["CharactersAndSynopsis"] = "cover";
    CurrentStepToPage["Cover"] = "cover";
    CurrentStepToPage["Dedication"] = "dedication";
    CurrentStepToPage["Page0"] = "0";
    CurrentStepToPage["Page1"] = "1";
    CurrentStepToPage["Page2"] = "2";
    CurrentStepToPage["Page3"] = "3";
    CurrentStepToPage["Page4"] = "4";
    CurrentStepToPage["Page5"] = "5";
    CurrentStepToPage["Page6"] = "6";
    CurrentStepToPage["Page7"] = "7";
    CurrentStepToPage["Page8"] = "8";
    CurrentStepToPage["Page9"] = "9";
    CurrentStepToPage["Page10"] = "10";
    CurrentStepToPage["Page11"] = "11";
    CurrentStepToPage["Page12"] = "12";
    CurrentStepToPage["Page13"] = "13";
    CurrentStepToPage["Page14"] = "14";
    CurrentStepToPage["Branding"] = "branding";
    CurrentStepToPage["End"] = "end";
})(CurrentStepToPage || (CurrentStepToPage = {}));
export var BookFormat;
(function (BookFormat) {
    BookFormat["v1"] = "v1";
})(BookFormat || (BookFormat = {}));
export const BookPageKeys = [
    "cover",
    "dedication",
    ...Array(NUM_BOOK_PAGES)
        .fill("")
        .map((_, i) => `${i}`),
    "end",
    "branding",
];
export const pageOrder = [
    { key: "cover", name: "Cover", needsImage: true, needsText: true },
    {
        key: "dedication",
        name: "Dedication",
        needsImage: false,
        needsText: true,
    },
    ...Array(NUM_BOOK_PAGES)
        .fill("")
        .map((_, i) => ({
        key: `${i}`,
        name: `Page ${i + 1}`,
        needsImage: true,
        needsText: true,
    })),
    { key: "end", name: "End", needsImage: false, needsText: false },
    { key: "branding", name: "About Us", needsImage: false, needsText: false },
];
export var BookStatuses;
(function (BookStatuses) {
    BookStatuses["NOT_STARTED"] = "NOT_STARTED";
    BookStatuses["LOADING"] = "LOADING";
    BookStatuses["COMPLETE"] = "COMPLETE";
    BookStatuses["FAILURE"] = "FAILURE";
})(BookStatuses || (BookStatuses = {}));
