"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ILLUSTRATIONS } from "../../helpers/illustration";
import { Button } from "../Button";
import { useErrorContext } from "../contexts/ErrorContext";
import { useGlobalContext } from "../contexts/GlobalContext";
export const OutOfTokensTitle = () => (_jsxs("span", { className: "flex flex-col -mt-4", children: [_jsx("img", { src: ILLUSTRATIONS.OUT_OF_TOKENS, className: "w-80 mb-8 mx-auto" }), "Looks like you're short a couple tokens"] }));
export const OutOfTokensMessage = () => {
    const { handleShowCreditsModal } = useGlobalContext();
    const { handleCloseModal } = useErrorContext();
    return (_jsxs("span", { children: ["But fear not! You can always purchase hundreds more for just a couple bucks. Click", " ", _jsx(Button, { onClick: () => {
                    handleShowCreditsModal(true);
                    handleCloseModal();
                }, as: "link", children: "here" }), " ", "to see options."] }));
};
