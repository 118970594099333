import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { formatShippingDisplayDate } from "../helpers/date";
import { callables } from "../firebase";
const defaultShippingOptions = [
    {
        methodClassification: "Economy",
        methodName: "Mail Innovations BPM",
        estimatedShipDateUTC: "2023-11-14",
        estimatedDeliveryDateUTC: "",
        price: 0,
        currency: "USD",
    },
    {
        methodClassification: "Standard",
        methodName: "FedEx Home Delivery",
        estimatedShipDateUTC: "2023-11-14",
        estimatedDeliveryDateUTC: "",
        price: 0,
        currency: "USD",
    },
    {
        methodClassification: "Express",
        methodName: "FedEx 2nd Day Air",
        estimatedShipDateUTC: "2023-11-14",
        estimatedDeliveryDateUTC: "",
        price: 0,
        currency: "USD",
    },
    {
        methodClassification: "Priority",
        methodName: "FedEx Standard Overnight",
        estimatedShipDateUTC: "2023-11-14",
        estimatedDeliveryDateUTC: "",
        price: 0,
        currency: "USD",
    },
];
export const ShippingOptions = ({ address, quantity, selectedShipping, setSelectedShipping, }) => {
    const [shippingOptions, setShippingOptions] = useState(defaultShippingOptions);
    const hasFullAddress = address?.address1 && address?.postal;
    useEffect(() => {
        if (hasFullAddress) {
            setShippingOptions(defaultShippingOptions);
            callables
                .getShippingOptions({
                orderId: "todo",
            })
                .then(({ data }) => {
                setShippingOptions(data || []);
            });
        }
        else {
            setShippingOptions(defaultShippingOptions);
        }
    }, [address]);
    return (_jsx("div", { className: "my-4 -mx-4 flex flex-wrap", children: shippingOptions.map((data) => (_jsx("div", { className: "w-2/4 p-2", children: _jsx(Card, { className: `hover:bg-gray-100 transition cursor-pointer ${selectedShipping === data.methodClassification
                    ? "border border-pink-500"
                    : ""}`, "aria-disabled": !hasFullAddress, onClick: () => setSelectedShipping(data.methodClassification), children: _jsxs("div", { children: [_jsxs("h3", { className: "font-bold m-0 text-lg", children: [data.methodClassification, data.price ? ` - $${data.price}` : ""] }), _jsxs("small", { className: "text-gray-500", children: ["Arrives by", " ", data.estimatedDeliveryDateUTC
                                    ? formatShippingDisplayDate(data.estimatedDeliveryDateUTC)
                                    : ""] })] }) }) }, data.methodClassification))) }));
};
