// API keys for Firebase services are ok to include in code or checked-in config files
// See: https://firebase.google.com/docs/projects/api-keys
const firebaseTestConfig = {
    apiKey: "AIzaSyBis3LOPpDT1f-DhXL-Jc0jgj5S8OYAo-Y",
    authDomain: "unicorntales-test.firebaseapp.com",
    projectId: "unicorntales-test",
    storageBucket: "unicorntales-test.appspot.com",
    messagingSenderId: "87293550441",
    appId: "1:87293550441:web:52c1f3d720e3e11ed767f8",
    measurementId: "G-7M74K9ZBLR",
};
const firebaseDevelopmentConfig = {
    apiKey: "AIzaSyDv3f75y528D8saLcfNdAfhlSnkoauyeYE",
    authDomain: "unicorntales-development.firebaseapp.com",
    projectId: "unicorntales-development",
    storageBucket: "unicorntales-development.appspot.com",
    messagingSenderId: "669529729425",
    appId: "1:669529729425:web:9886411a553bb47f519230",
    measurementId: "G-2RL6521FTT",
};
const firebaseConfigStaging = {
    apiKey: "AIzaSyAhEELU-gYmVWhYXnt_rUlfRBdefKEAakY",
    authDomain: "unicorntales-staging.firebaseapp.com",
    projectId: "unicorntales-staging",
    storageBucket: "unicorntales-staging.appspot.com",
    messagingSenderId: "908541350101",
    appId: "1:908541350101:web:8819e8a8610ee4e318525c",
    measurementId: "G-VCZXCKKV76",
};
const firebaseConfigProduction = {
    apiKey: "AIzaSyAWGz_7Pg2D3xWwfnMf5LQ9-4N5Jf9C2KM",
    authDomain: "unicorntales.firebaseapp.com",
    projectId: "unicorntales",
    storageBucket: "unicorntales.appspot.com",
    messagingSenderId: "126624988831",
    appId: "1:126624988831:web:82f74edb7d2e6b13b0ecaa",
    measurementId: "G-6MQTDTK5K0",
};
const appEnv = process.env.REACT_APP_ENV;
export const env = {
    appEnv,
    paths: {
        graphics: "images/graphics",
        illustrations: "images/illustrations",
        logos: "images/logos",
    },
    googleMapsApiKey: appEnv === "production"
        ? "AIzaSyBITn2QZKwVeoNtA6nA8x-kBWK1PDjPMZU"
        : appEnv === "staging"
            ? "AIzaSyAQLYQEV6X7jmoNGaLp5fud7_3kuxsS5OU"
            : "AIzaSyCmYQY4qGsgbGBrGVLBwXbYwfmxc4U_9qE",
    firebaseConfig: appEnv === "production"
        ? firebaseConfigProduction
        : appEnv === "staging"
            ? firebaseConfigStaging
            : appEnv === "development"
                ? firebaseDevelopmentConfig
                : firebaseTestConfig,
    firebaseApiUrl: appEnv === "production"
        ? "https://us-central1-unicorntales.cloudfunctions.net"
        : appEnv === "staging"
            ? "https://us-central1-unicorntales-staging.cloudfunctions.net"
            : appEnv === "development"
                ? "http://127.0.0.1:5001/unicorntales-development/us-central1"
                : "http://127.0.0.1:5001/unicorntales-test/us-central1",
    stripePublicKey: appEnv === "production"
        ? "pk_live_51OTRUvDbv8X79i9dBvQMlAAIu16Mf03gDMx0sythALGmYxDjA0msFBQObCbe5nRHDhv0MxnS9sGJlNsjiZWWLbIf00pAxkdoCA"
        : "pk_test_51OTRUvDbv8X79i9d469McwxQSJhp2uJArD2RT9DjKaBhxlRDN5BPJs8Pl27kJm4Jsq1oq17zrC8gGNDl4ogUwkat00zFUN9tWu",
};
