import { db } from "../firebase";
import { collection, query, where, getDocs, } from "firebase/firestore";
export const ORDER_COLLECTION = "orders";
export class OrderCollection {
    docRef;
    state;
    subscriptions;
    constructor() {
        this.docRef = collection(db, ORDER_COLLECTION);
        this.state = [];
        this.subscriptions = [];
    }
    get = async (queryClause) => {
        const getOrders = await query(this.docRef, where(queryClause[0], queryClause[1], queryClause[2]), where("data.stripe.session.status", "==", "complete"));
        const docSnap = await getDocs(getOrders);
        const newState = [];
        docSnap.forEach((data) => {
            const order = { id: data.id, ...data.data() };
            newState.push(order);
        });
        this.state = newState;
        return newState;
    };
}
