import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { getIframeUrl } from "../helpers/routes";
import { useBook } from "../hooks/useBook";
import { useQueryParams } from "../hooks/useQueryParams";
import { BookNavigation } from "./BookNavigation";
import { usePublicImage } from "../hooks/usePublicImage";
import { getPageDataFromBook } from "../helpers/page";
import { useNavigate, useParams } from "react-router-dom";
import { pageOrder } from "shared/types/book";
export const ViewStory = () => {
    const { bookId } = useParams();
    const navigate = useNavigate();
    const { page: initialPage = "cover" } = useQueryParams();
    const [page, setPage] = useState(initialPage);
    const { book, hasFetchedBook } = useBook({
        bookId: bookId || null,
        shouldSubscribe: false,
    });
    const currentPageData = getPageDataFromBook(book, page);
    const imageDownloadUrl = usePublicImage(currentPageData.selectedIllustration?.url);
    useEffect(() => {
        if ((book?.data && !book?.data?.isPublic) || (hasFetchedBook && !book)) {
            navigate("/");
        }
    }, [book]);
    const currentIndex = pageOrder.findIndex((p) => p.key === page);
    const hasNextPage = currentIndex < pageOrder.length - 1;
    const navigateBook = (isNext) => () => {
        setPage((prevPage) => {
            let nextPage;
            pageOrder.forEach((p, i) => {
                if (isNext && prevPage === p.key) {
                    nextPage = pageOrder[i + 1]?.key;
                }
                else if (!isNext && prevPage === p.key) {
                    nextPage = pageOrder[i - 1]?.key;
                }
            });
            return nextPage || prevPage;
        });
    };
    useEffect(() => {
        const handleKeydownNav = (event) => {
            if ([`ArrowLeft`, `ArrowRight`].includes(event?.key)) {
                const isNext = event?.key === `ArrowRight`;
                navigateBook(isNext)();
            }
        };
        document.addEventListener("keydown", handleKeydownNav);
        return () => {
            document.removeEventListener("keydown", handleKeydownNav);
        };
    }, []);
    if (!book)
        return null;
    const iframeSrc = getIframeUrl({ ...currentPageData, imageDownloadUrl });
    return (_jsx(BookNavigation, { iframeSrc: iframeSrc, handleClickNext: hasNextPage ? navigateBook(true) : undefined, handleClickPrev: currentIndex > 0 ? navigateBook(false) : undefined, showClickLoadNewImage: false, showClickBuy: false }));
};
