"use client";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Sidebar } from "flowbite-react";
import { useMakeStoryContext } from "../../contexts/MakeStoryContext";
import { Label } from "flowbite-react";
import { cleanInput } from "../../../helpers/input";
import { SidebarSection } from "./SidebarSection";
import { SidebarImage } from "./SidebarImage";
import { useState } from "react";
import { getNextIllustrationKey } from "../../../helpers/page";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoWarningOutline } from "react-icons/io5";
import { LoadingIcon } from "../LoadingIcon";
import { ERROR_CODE_MESSAGE_MAP } from "../../../constants";
import Textarea from "react-textarea-autosize";
import { ImageFeedbackForm } from "../ImageFeedbackForm";
import { useBookPage } from "../../../hooks/useBookPage";
import { useBookModelContext } from "../../contexts/BookModelContext";
export const SidebarPage = ({ pageKey, pageName, }) => {
    // pre-built hooks
    const { bookId, handlers, currentPageKey } = useMakeStoryContext();
    const { model: bookModel } = useBookModelContext();
    const { page: pageData, updatePage, updatePageState, } = useBookPage({
        bookId,
        pageKey,
        shouldSubscribe: true,
        bookModel,
    });
    const { illustrations = {} } = pageData || {};
    const [hasUnseenImages, setHasUnseenImages] = useState(false);
    // derived data
    const isActive = pageKey === currentPageKey;
    useEffect(() => {
        if (Object.values(illustrations)
            .map((illustration) => illustration.url)
            .includes("")) {
            setHasUnseenImages(true);
        }
        else if (hasUnseenImages &&
            !Object.values(illustrations)
                .map((illustration) => illustration.url)
                .includes("") &&
            isActive) {
            setHasUnseenImages(false);
        }
    }, [pageData?.illustrations, isActive]);
    const handleFetchIllustration = (imageFeedback) => {
        // if (!pageData?.selectedIllustrationKey) return;
        const nextIllustrationKey = getNextIllustrationKey(illustrations);
        updatePageState({
            [`illustrations.${nextIllustrationKey}`]: {
                url: "",
            },
        });
        handlers.generateImage({
            pageKey,
            nextIllustrationKey,
            sourceIllustrationKey: pageData?.selectedIllustrationKey || "0",
            feedback: imageFeedback,
        });
    };
    const handleUpdateCurrentStep = () => {
        handlers.updateCurrentStep(pageKey);
    };
    const handleUpdateSelectedIllustrationKey = (newIndex) => {
        if (newIndex === pageData?.selectedIllustrationKey)
            return;
        updatePage({ [`selectedIllustrationKey`]: newIndex });
    };
    const isLoadingImages = Object.values(illustrations)
        .map((illustration) => illustration.url)
        .includes("");
    const selectedImageConversation = [
        ...(pageData?.selectedIllustration?.conversation || []),
    ].pop();
    const selectedImageDescription = selectedImageConversation?.role === "assistant"
        ? selectedImageConversation.content
        : pageData?.enhancedImageDescription;
    // we have to do this because if we pass onClick: undefined it
    // still prevents default click behavior *eyeroll
    const handler = isActive ? {} : { onClick: handleUpdateCurrentStep };
    return (_jsx(Sidebar.Collapse, { open: isActive, label: pageName, className: `flex-row-reverse ${isActive ? "font-bold" : ""}`, icon: isLoadingImages
            ? LoadingIcon
            : hasUnseenImages
                ? IoCheckmarkOutline
                : pageData?.error
                    ? () => _jsx(IoWarningOutline, { className: "text-rose-600 text-base" })
                    : undefined, ...handler, children: _jsxs(SidebarSection, { children: [pageData?.needsText && isActive && (_jsxs(_Fragment, { children: [_jsx("div", { className: "block", children: _jsx(Label, { htmlFor: "text", value: "Text" }) }), _jsx(Textarea, { className: "page-text border w-full rounded-md text-sm border-gray-300 leading-5 text-gray-900", defaultValue: pageData?.text, onBlur: (e) => {
                                const value = cleanInput(e.target.value);
                                updatePage({ text: value });
                            } })] })), pageData?.needsImage && isActive && (_jsxs("div", { className: "page-image", children: [_jsx("div", { className: "block mt-4 flex justify-between", children: _jsx(Label, { htmlFor: "image", value: "Select Image" }) }), pageData.error && (_jsx("div", { className: "text-sm text-rose-600 font-bold my-4", children: ERROR_CODE_MESSAGE_MAP[pageData.error.code] ||
                                "Unknown error occurred" })), _jsx("div", { className: "flex flex-wrap w-full overflow-y-auto min-h-[50vw] md:min-h-[8rem] max-h-[20rem]", children: Object.entries(illustrations)
                                .reverse()
                                .map(([illustrationKey, { url = "" }]) => {
                                return (_jsx(SidebarImage, { imageUri: url, handleClickImage: () => {
                                        handleUpdateSelectedIllustrationKey(illustrationKey);
                                    }, selected: illustrationKey === pageData?.selectedIllustrationKey }, url || illustrationKey));
                            }) }), _jsx(ImageFeedbackForm, { isLoadingImages: isLoadingImages, handleFetchIllustration: handleFetchIllustration, selectedImageDescription: selectedImageDescription || "" })] }))] }) }));
};
