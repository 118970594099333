import { BookModel } from "../models/book";
import { useDocument } from "./useDocument";
import { useMemo } from "react";
export const useBook = ({ bookId, bookModel, shouldSubscribe, }) => {
    const documentModel = useMemo(() => {
        if (!bookId)
            return null;
        if (typeof bookModel !== "undefined")
            return bookModel;
        return new BookModel(bookId);
    }, [bookModel, bookId]);
    const { document: book, hasFetchedDocument: hasFetchedBook, updateDocument: updateBook, updateDocumentState: updateBookState, } = useDocument({
        documentId: bookId,
        documentModel,
        shouldSubscribe,
    });
    return {
        book,
        hasFetchedBook,
        updateBook,
        updateBookState,
    };
};
