import { useEffect, useState } from "react";
import { useImageContext } from "../components/contexts/ImageContext";
import { getDownloadUrl } from "../controllers/image";
/**
 * gets public url for gcs private url
 *
 * @param imageUri string the google cloud storage link (private)
 * @returns string the public url
 */
export const usePublicImage = (imageUri) => {
    const { getFetchCacheKey, getResolvedCacheKey, setFetchCacheKey, setResolvedCacheKey, } = useImageContext();
    const [imageUrl, setImageUrl] = useState(getResolvedCacheKey(imageUri || "") || null);
    useEffect(() => {
        setImageUrl(null);
        if (!imageUri) {
            return;
        }
        const resolvedValue = getResolvedCacheKey(imageUri);
        const fetchingValue = getFetchCacheKey(imageUri);
        if (typeof resolvedValue !== "undefined") {
            if (resolvedValue === null) {
                console.error(`Fetch signed url failed for uri ${imageUri}`);
            }
            else {
                setImageUrl(resolvedValue);
            }
            return;
        }
        if (typeof fetchingValue !== "undefined") {
            fetchingValue
                .then((value) => {
                setImageUrl(value);
            })
                .catch(console.error);
            return;
        }
        setImageUrl(null);
        const fetchCacheValue = getDownloadUrl(imageUri);
        setFetchCacheKey(imageUri, fetchCacheValue);
        fetchCacheValue.then((value) => {
            setResolvedCacheKey(imageUri, value);
            setImageUrl(value);
        });
        return () => {
            setImageUrl(null);
        };
    }, [imageUri ? `${imageUri}` : ""]);
    return imageUrl;
};
