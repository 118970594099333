import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from "flowbite-react";
import { usePublicImage } from "../hooks/usePublicImage";
import { GRAPHICS } from "../helpers/illustration";
import { Button } from "./Button";
export const BookCard = ({ book, handleClickBook, handleDeleteBook, handleBuyBook, }) => {
    const coverData = book.data?.pages?.cover;
    const coverIllustrationUrl = coverData?.illustrations?.[coverData?.selectedIllustrationKey ?? "0"]?.url;
    const coverImageSrc = usePublicImage(coverIllustrationUrl);
    console.log({ handleDeleteBook });
    return (_jsxs(Card, { className: "relative hover-parent w-full md:w-60 m-4 text-center relative cursor-pointer hover:border-pink-500 hover:border-2 transition", onClick: handleClickBook, style: {
            backgroundColor: "white",
            backgroundImage: `url("${GRAPHICS.COVER}")`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            borderRadius: 0,
        }, children: [_jsx("h4", { className: "text-center text-xl", children: book.data?.pages?.cover?.text || "Untitled" }), coverImageSrc && (_jsxs("div", { className: "relative", children: [_jsx("img", { className: "w-full absolute", src: GRAPHICS.VIGNETTE }), _jsx("img", { className: "w-full ", src: coverImageSrc })] })), handleDeleteBook && handleBuyBook ? (_jsxs("div", { className: "absolute flex bottom-0 left-0 right-0 hover-child duration-500", children: [_jsx(Button, { as: "link", className: "transition w-full flex p-1 bg-pink-500 hover:bg-pink-600 text-white", onClick: handleClickBook, children: _jsx("span", { className: "text-center m-auto block", children: "Edit" }) }), _jsx(Button, { as: "link", className: "transition w-full flex p-1 bg-pink-500 hover:bg-pink-600 text-white", onClick: handleBuyBook, children: _jsx("span", { className: "text-center m-auto block", children: "Buy" }) }), _jsx(Button, { as: "link", className: "transition w-full flex p-1 bg-pink-500 hover:bg-pink-600 text-white", onClick: handleDeleteBook, children: _jsx("span", { className: "text-center m-auto block", children: "Delete" }) })] })) : null] }, book.id));
};
