import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Avatar } from "flowbite-react";
import { useBooks } from "../hooks/useBooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "./contexts/UserContext";
import { Button } from "./Button";
import Modal from "./Modal";
import React, { useEffect, useState } from "react";
import { PurchaseCredits } from "./PurchaseCredits";
import { useOrders } from "../hooks/useOrders";
import { formatOrderDisplayDate } from "../helpers/date";
import { GoChevronRight, GoChevronDown } from "react-icons/go";
import { BookCard } from "./BookCard";
import { getBookItemData, getCreditItemData, sortOrderUpdates, } from "../helpers/order";
import { OrderDetails } from "./OrderDetails";
import { callables } from "../firebase";
import { OrderModel } from "../models/order";
import { BookModel } from "../models/book";
import { isRpiProduct } from "shared/types/product";
import { v4 } from "uuid";
import { productCostCents } from "shared/config/product";
const TableOrderRow = ({ order, bookName, className, }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [mostRecentUpdate, ...updates] = sortOrderUpdates(order);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: `${className || ""} px-4 bg-white flex items-center dark:border-gray-700 dark:bg-gray-800`, children: [_jsx(Button, { as: "link", color: "default", onClick: () => setIsExpanded((p) => !p), children: isExpanded ? _jsx(GoChevronDown, {}) : _jsx(GoChevronRight, {}) }), _jsx("div", { className: "p-4 whitespace-nowrap basis-1/4 font-medium text-gray-900 dark:text-white", children: order.id }), _jsx("div", { className: "p-4 hidden md:block basis-1/4", children: formatOrderDisplayDate((order?.createdAt?.seconds || 0) * 1000) }), _jsx("div", { className: "p-4 hidden md:block basis-1/4", children: bookName }), _jsx("div", { className: "p-4 hidden md:block basis-1/4", children: mostRecentUpdate.title })] }), " ", isExpanded && (_jsx("div", { className: "bg-white  p-4 pt-0", children: _jsx("div", { className: "bg-gray-100 p-4", children: _jsx(OrderDetails, { order: order }) }) }))] }));
};
export const Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useUserContext();
    const queryClause = React.useMemo(() => {
        return ["userId", "==", user?.userId];
    }, [user?.userId]);
    const { books, hasFetchedBooks, updateBooksState } = useBooks(user?.userId ? queryClause : undefined);
    const { orders, hasFetchedOrders } = useOrders(user?.userId);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleCloseModal = () => setIsModalOpen(false);
    const balance = user?.profile?.balanceCents;
    const bookOrders = orders.filter((order) => isRpiProduct(getBookItemData(order)?.productId));
    const creditOrders = orders.filter((order) => !isRpiProduct(getBookItemData(order)?.productId));
    useEffect(() => {
        if (location.hash && hasFetchedBooks && hasFetchedOrders) {
            setTimeout(() => {
                document.querySelector(location.hash)?.scrollIntoView();
            }, 100);
        }
    }, [location.hash, hasFetchedBooks, hasFetchedOrders]);
    const sortedBooks = books.sort((a, b) => {
        const aDateUpdated = a.updatedAt
            ? new Date(a.updatedAt.seconds * 1000).getTime()
            : 0;
        const bDateUpdated = b.updatedAt
            ? new Date(b.updatedAt.seconds * 1000).getTime()
            : 0;
        return aDateUpdated < bDateUpdated ? 1 : -1;
    });
    const handleBuyBook = (book) => async (e) => {
        e.stopPropagation();
        if (!user.userId)
            return;
        const itemId = v4();
        const { id: orderId } = await OrderModel.createOrder({
            userId: user.userId,
            data: {
                items: {
                    [itemId]: {
                        productId: "book-hard-8.5x11",
                        priceCents: productCostCents["book-hard-8.5x11"],
                        quantity: 1,
                        book: {
                            id: book.id,
                        },
                    },
                },
            },
        });
        // kick this off now
        callables.generateBookPdfs({
            orderId,
            bookId: book.id,
            itemId,
        });
        setTimeout(() => {
            navigate(`/order/${orderId}`);
        }, 500);
    };
    const handleDeleteBook = (book) => async (e) => {
        e.stopPropagation();
        const shouldDelete = await confirm(`Are you sure you want to delete "${book.data.pages?.cover?.text || "Unititled"}"?`);
        if (!shouldDelete)
            return;
        const model = new BookModel(book.id);
        await model.update({ archivedAt: new Date() });
        updateBooksState((prev) => prev.filter((b) => b.id !== book.id));
    };
    return (_jsxs("div", { className: "md:w-[70rem] p-8 m-auto", children: [_jsxs("div", { className: "text-center", children: [_jsx(Avatar, { size: "xl", rounded: true, img: user?.auth?.photoURL || "/avatar.png", placeholderInitials: (user?.auth?.displayName || "")
                            .split(" ")
                            .map((str) => str[0])
                            .join("") }), _jsx("p", { className: "text-2xl font-header font-bold mt-6 ", children: user?.auth?.displayName }), _jsx("p", { children: user?.auth?.email }), _jsxs("p", { className: "mt-4", children: [_jsx("span", { className: "font-bold", children: "Credits: " }), typeof balance === "number" ? Math.floor(balance) : ""] }), _jsx(Button, { color: "alternate", className: "my-2", onClick: () => setIsModalOpen(true), children: "Purchase credits" })] }), _jsxs(Modal, { header: "Purchase Credits", isOpen: isModalOpen, handleClose: handleCloseModal, size: "lg", children: [_jsx(PurchaseCredits, {}), ","] }), _jsx("h2", { id: "books", className: "text-2xl font-bold my-4 mt-8", children: "My Books" }), _jsx("div", { className: "flex-wrap flex md:-m-4", children: sortedBooks.map((book) => {
                    return (_jsx(BookCard, { book: book, handleClickBook: () => {
                            navigate(`/make-story/${book.id}`);
                        }, handleBuyBook: handleBuyBook(book), handleDeleteBook: handleDeleteBook(book) }, book.id));
                }) }), !books?.length && hasFetchedBooks && (_jsxs("p", { className: "text-center", children: ["No books yet! Click", " ", _jsx(Link, { className: "link", to: `/make-story`, children: "here" }), " ", "to get started"] })), _jsx("h2", { id: "orders", className: "text-2xl font-bold my-4 mt-8", children: "My Book Orders" }), _jsx("div", { className: "max-w-full overflow-auto", children: bookOrders.map((order, i) => {
                    const hasBorder = i < bookOrders.length - 1;
                    return (_jsx(TableOrderRow, { order: order, bookName: books.find(({ id }) => {
                            const bookItem = getBookItemData(order);
                            return id === bookItem?.book?.id;
                        })?.data?.pages?.cover?.text, className: hasBorder ? "border-b" : "" }, order.id));
                }) }), _jsx("div", { className: "pb-3", children: !bookOrders?.length && hasFetchedOrders && (_jsx("p", { className: "text-center mt-4", children: "No orders" })) }), _jsx("h2", { className: "text-2xl font-bold my-4 mt-8", children: "My Token Orders" }), _jsx("div", { className: "max-w-full overflow-auto", children: creditOrders.map((order, i) => {
                    const hasBorder = i < creditOrders.length - 1;
                    const creditItem = getCreditItemData(order);
                    return (_jsxs("div", { className: `${hasBorder ? "border-b" : ""} px-4 bg-white flex items-center dark:border-gray-700 dark:bg-gray-800`, children: [_jsx("div", { className: "p-4 whitespace-nowrap w-1/2 md:basis-1/3 font-medium text-gray-900 dark:text-white", children: order.id }), _jsx("div", { className: "p-4 hidden md:block basis-1/3", children: formatOrderDisplayDate((order?.createdAt?.seconds || 0) * 1000) }), _jsxs("div", { className: "p-4 w-1/2 text-right md:text-left md:basis-1/3", children: [creditItem?.productId.split("-")[1], " Tokens"] })] }));
                }) }), _jsx("div", { className: "pb-20", children: !creditOrders?.length && hasFetchedOrders && (_jsx("p", { className: "text-center mt-4", children: "No orders" })) })] }));
};
