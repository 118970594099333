import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const FlipCard = ({ isFlipped, Front, Back, hasBorder, dimensions, duration = 300, }) => {
    return (_jsx("div", { className: `w-full ${dimensions.width} ${dimensions.height} rounded-lg m-4`, style: { perspective: "1000px" }, children: _jsxs("div", { className: `inner relative w-full h-full text-center transition duration-${duration} ${hasBorder ? "shadow-md" : ""}`, style: {
                transformStyle: "preserve-3d",
                ...(isFlipped ? { transform: "rotateY(180deg)" } : {}),
            }, children: [_jsx("div", { className: `front bg-white absolute top-0 bottom-0 right-0 left-0 p-5 overflow-hidden rounded-lg flex items-center ${hasBorder ? "border" : ""} `, style: { backfaceVisibility: "hidden" }, children: _jsx("div", { className: "w-full", children: _jsx(Front, {}) }) }), _jsx("div", { className: `back bg-white absolute top-0 bottom-0 right-0 left-0 p-5 overflow-hidden rounded-lg flex items-center ${hasBorder ? "border" : ""} `, style: {
                        backfaceVisibility: "hidden",
                        transform: "rotateY(180deg)",
                    }, children: _jsx(Back, {}) })] }) }));
};
