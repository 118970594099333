export const isProductId = (value) => {
    return (value === "credits-100" ||
        value === "credits-300" ||
        value === "credits-500" ||
        value === "book-hard-8.5x11");
};
export const isRpiProduct = (productId) => {
    return productId === "book-hard-8.5x11";
};
export const isCreditsProduct = (productId) => {
    return productId.startsWith("credits");
};
