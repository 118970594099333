import { db } from "../firebase";
import { collection, query, where, getDocs, } from "firebase/firestore";
export const BOOK_COLLECTION = "books";
export class BookCollection {
    docRef;
    state;
    subscriptions;
    constructor() {
        this.docRef = collection(db, BOOK_COLLECTION);
        this.state = [];
        this.subscriptions = [];
    }
    //"userId", "==", userId
    get = async (queryClause) => {
        const getBooks = await query(this.docRef, where(queryClause[0], queryClause[1], queryClause[2]));
        const docSnap = await getDocs(getBooks);
        const newState = [];
        docSnap.forEach((data) => {
            const book = { id: data.id, ...data.data() };
            newState.push(book);
        });
        this.state = newState;
        return newState;
    };
}
