import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { useBooks } from "../hooks/useBooks";
import { BookCard } from "./BookCard";
export const ViewAll = () => {
    const navigate = useNavigate();
    const { books: featuredBooks } = useBooks(["data.isFeatured", "==", true]);
    const { books: publicBooks } = useBooks(["data.isCommunityBook", "==", true]);
    const featuredBookIds = featuredBooks.map((b) => b.id);
    return (_jsxs("div", { className: "md:w-[70rem] p-8 m-auto", children: [_jsx("h2", { className: "text-3xl font-bold mt-8 mb-1", children: "Featured Books" }), _jsx("span", { children: "Check out some of these books to give you some inspiration" }), _jsx("div", { className: "flex-wrap flex -m-4 mt-4", children: featuredBooks.map((book) => {
                    return (_jsx(BookCard, { book: book, handleClickBook: () => {
                            navigate(`/view/${book.id}`);
                        } }, book.id));
                }) }), _jsx("h2", { className: "text-3xl font-bold mt-8  mb-1", children: "Community Books" }), _jsx("span", { children: "Here are some of the books made by people like you!" }), _jsx("div", { className: "flex-wrap flex -m-4  mt-4", children: publicBooks.map((book) => {
                    if (featuredBookIds.includes(book.id))
                        return null;
                    return (_jsx(BookCard, { book: book, handleClickBook: () => {
                            navigate(`/view/${book.id}`);
                        } }, book.id));
                }) })] }));
};
