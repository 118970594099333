import { env } from "../utils/env";
const makeIllustrationPath = (fileName) => {
    return `/${env.paths.illustrations}/${fileName}.png`;
};
const makeGraphicsPath = (fileName) => {
    return `/${env.paths.graphics}/${fileName}.png`;
};
const makeLogosPath = (fileName) => {
    return `/${env.paths.logos}/${fileName}.png`;
};
export const ILLUSTRATIONS = {
    STORYBOOK: makeIllustrationPath("storybook"),
    MAKE_STORY_INTRO: makeIllustrationPath("girl-making-story"),
    MAKE_STORY_SUCCESS: makeIllustrationPath('girl-celebrating-2'),
    MAKE_STORY_FAILURE: makeIllustrationPath('girl-book-incorrect'),
    OUT_OF_TOKENS: makeIllustrationPath('girl-broke'),
    TOKENS_PURCHASE: makeIllustrationPath('girl-money-dance'),
    BOOK_PURCHASE: makeIllustrationPath('girl-shopping'),
    PAGE_NOT_FOUND: makeIllustrationPath('girl-lost'),
    FAQ: makeIllustrationPath("faq"),
    GENERAL_ERROR: makeIllustrationPath("robot-unavailable"),
    IMAGE_FAILURE: makeIllustrationPath("robot-fail"),
    // Book creation steps
    MUSE: makeIllustrationPath("muse"),
    ROBOT_WRITERS: makeIllustrationPath("robot-writers"),
    BLUEPRINT: makeIllustrationPath("blueprint"),
    MONOLOGUE: makeIllustrationPath("monologue"),
    DOLLS: makeIllustrationPath("girl-dolls"),
    SCULPTING: makeIllustrationPath("sculpting-2"),
    RIVER: makeIllustrationPath("river"),
    SUBPLOTS: makeIllustrationPath("subplots"),
    COMMAS: makeIllustrationPath("commas"),
    GHOULS: makeIllustrationPath("ghouls"),
    POLISHING: makeIllustrationPath("polishing"),
    INFUSING: makeIllustrationPath("infusing-pages"),
    BRAINSTORM: makeIllustrationPath("brainstorm"),
    ART_SHOW: makeIllustrationPath("art-show"),
    PAPER: makeIllustrationPath("paper"),
    CALLIGRAPHY: makeIllustrationPath("calligraphy"),
    PAINTING: makeIllustrationPath("painting"),
    INK_DRYING: makeIllustrationPath("ink_dry"),
    BOOKBINDING: makeIllustrationPath("bookbinding"),
    MAGIC_DUST: makeIllustrationPath("magic-dust"),
};
export const GRAPHICS = {
    COVER: makeGraphicsPath("cover-test"),
    PAGE: makeGraphicsPath("page"),
    VIGNETTE: makeGraphicsPath("vignette")
};
export const LOGOS = {
    "512": makeLogosPath("logo-512")
};
