"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BookNavigation } from "../BookNavigation";
import { getIframeUrl } from "../../helpers/routes";
import { useMakeStoryContext } from "../contexts/MakeStoryContext";
import { usePublicImage } from "../../hooks/usePublicImage";
import { getNextIllustrationKey, getNextStep, getPrevStep, } from "../../helpers/page";
import { useBookPage } from "../../hooks/useBookPage";
import { useBookModelContext } from "../contexts/BookModelContext";
import { Label, Checkbox, Tooltip, TextInput } from "flowbite-react";
import { useDocument } from "../../hooks/useDocument";
import React from "react";
import { PiCopy } from "react-icons/pi";
import { useErrorContext } from "../contexts/ErrorContext";
import { Button } from "../Button";
const ShareTooltip = ({ children }) => {
    const { setMessage } = useErrorContext();
    const { bookId } = useMakeStoryContext();
    const { model: bookModel } = useBookModelContext();
    const { document: shareSettings, updateDocument: updateBook } = useDocument({
        documentId: bookId,
        shouldSubscribe: true,
        documentModel: bookModel,
        reducer: (book) => {
            const { isPublic, isCommunityBook } = book?.data || {};
            return { isPublic, isCommunityBook };
        },
    });
    const bookLink = React.useMemo(() => {
        return `${window.location.host}/view/${bookId}`;
    }, [bookId]);
    return (_jsx(Tooltip, { trigger: "click", style: "light", content: _jsxs("div", { className: "p-2 text-left", children: [_jsxs("div", { className: "mb-4 flex items-center ", children: [_jsx(Checkbox, { id: "isPublicCheckbox", checked: !!shareSettings?.isPublic, color: "pink", onChange: (event) => {
                                const isChecked = event.target.checked;
                                updateBook({
                                    [`data.isPublic`]: isChecked,
                                    [`data.isCommunityBook`]: isChecked,
                                });
                            } }), _jsx(Label, { className: "ml-2  cursor-pointer", htmlFor: "isPublicCheckbox", children: "Book is publicly viewable" })] }), _jsxs("div", { "aria-disabled": shareSettings?.isPublic ? "false" : "true", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(TextInput, { type: "text", disabled: !shareSettings?.isPublic, value: bookLink, className: "w-full" }), _jsx(Tooltip, { style: "light", content: _jsx("span", { className: "w-32 text-center block", children: "Copy link to clipboard" }), children: _jsx(Button, { className: "px-0 bg-transparent enabled:hover:bg-transparent hover:text-pink-500 border-none rounded-md ml-2", color: "default", onClick: () => {
                                            navigator.clipboard.writeText(bookLink);
                                            setMessage({
                                                messageType: "success",
                                                title: "Copied link to clipboard",
                                                messageUX: "alert",
                                                message: "",
                                            });
                                        }, children: _jsx(PiCopy, { className: "w-6 h-6 -mx-3" }) }) })] }), _jsxs("div", { className: "mt-2 mb-2 flex items-center cursor-pointer", children: [_jsx(Checkbox, { id: "isCommunityBookCheckbox", checked: !!shareSettings?.isCommunityBook, color: "pink", onChange: (event) => {
                                        const isChecked = event.target.checked;
                                        updateBook({ [`data.isCommunityBook`]: isChecked });
                                    } }), _jsx(Label, { className: "ml-2  cursor-pointer", htmlFor: "isCommunityBookCheckbox", children: "Show my book in community feed" })] })] })] }), children: children }));
};
export const AdminBookNavigation = ({ bookId, currentPageKey, }) => {
    const { handlers } = useMakeStoryContext();
    const { model: bookModel } = useBookModelContext();
    const { navigateBook, buyBook, generateImage } = handlers;
    const { page, updatePageState } = useBookPage({
        bookId,
        pageKey: currentPageKey,
        shouldSubscribe: true,
        bookModel,
    });
    const currentSelectedImageDownloadUrl = usePublicImage(page?.selectedIllustration?.url);
    const isLoadingImage = Object.values(page?.illustrations ?? {})
        .map((illustration) => illustration?.url)
        .includes("");
    const handleLoadImage = isLoadingImage
        ? undefined
        : () => {
            const nextIllustrationKey = getNextIllustrationKey(page?.illustrations || {});
            updatePageState({
                [`illustrations.${nextIllustrationKey}`]: {
                    url: "",
                },
            });
            generateImage({
                pageKey: currentPageKey,
                nextIllustrationKey,
                sourceIllustrationKey: page?.selectedIllustrationKey || "0",
                feedback: "",
            });
        };
    return (_jsx(BookNavigation, { iframeSrc: getIframeUrl({
            ...page,
            imageDownloadUrl: currentSelectedImageDownloadUrl,
            isLoadingImage,
        }), handleClickNext: getNextStep(currentPageKey) ? navigateBook(true) : null, handleClickPrev: getPrevStep(currentPageKey) ? navigateBook(false) : null, handleLoadNewImage: handleLoadImage, handleClickBuy: buyBook, isLoadingImage: isLoadingImage, ShareTooltip: ShareTooltip }));
};
