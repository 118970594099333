"use client";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Spinner } from "flowbite-react";
import { BookStatuses } from "shared/types/book";
import { useUserContext } from "../../contexts/UserContext";
import { callables } from "../../../firebase";
import { useErrorContext } from "../../contexts/ErrorContext";
import { GuidedTourModal } from "../Modals/GuidedTourModal";
import { GuidedTour } from "../GuidedTour";
import { ImageExplanationModal } from "../Modals/ImageExplanationModal";
import { ErrorCode } from "shared/types/error";
import { MissingBookDataModal } from "../Modals/MissingBookDataModal";
import { useBookStatus } from "../../../hooks/useBookStatus";
import { MakeStoryEditLayer } from "./MakeStoryEditLayer";
import { useBookModelContext } from "../../contexts/BookModelContext";
import { BookLoadingBar } from "../BookLoadingBar";
const BookLoadingScreenWithSubscribeBook = ({ bookId }) => {
    return (_jsxs("div", { className: `bg-[#ffffffbf] fixed h-full top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center`, children: [_jsx(Spinner, { size: "lg", color: "pink" }), _jsx(BookLoadingBar, { bookId: bookId })] }));
};
export const MakeStoryLoadingAndModalsLayer = ({ book, }) => {
    const { model: bookModel } = useBookModelContext();
    const { bookStatus } = useBookStatus({
        bookId: book.id,
        bookModel,
    });
    const { handleError } = useErrorContext();
    const { user, getUserProfile } = useUserContext();
    const [showGuidedTourModal, setShowGuidedTourModal] = useState(false);
    const [startGuidedTour, setStartGuidedTour] = useState(false);
    const [shouldPauseTour, setShouldPauseTour] = useState(false);
    const [showImageExplainerModal, setShowImageExplainerModal] = useState(false);
    const [showMissingBookDataModal, setShowMissingBookDataModal] = useState(false);
    const handleShowImageExplainerModal = () => {
        setShouldPauseTour(true);
        setShowImageExplainerModal(true);
    };
    const handleCloseImageExplainerModal = () => {
        setShowImageExplainerModal(false);
        setShouldPauseTour(false);
    };
    const handleFinishBookCreation = async () => {
        try {
            await callables.createBookData({
                bookId: book?.id,
                options: {},
            });
        }
        catch (e) {
            handleError(e);
        }
    };
    const handleBookStatusChange = async () => {
        if (!user.userId)
            return;
        if (bookStatus === BookStatuses.FAILURE) {
            const profile = await getUserProfile(user.userId);
            const balance = profile?.balanceCents || 0;
            if (balance <= 6) {
                handleError({ message: ErrorCode.OutOfTokens });
            }
            else {
                setShowMissingBookDataModal(true);
            }
        }
        else if (bookStatus === BookStatuses.COMPLETE &&
            localStorage.getItem("unicorntales_tour") !== "1") {
            setShowGuidedTourModal(true);
        }
    };
    useEffect(() => {
        handleBookStatusChange();
    }, [bookStatus]);
    const isBookComplete = bookStatus === BookStatuses.COMPLETE;
    if (!user.userId)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(MakeStoryEditLayer, { book: book, userId: user.userId, handleShowImageExplainerModal: handleShowImageExplainerModal, handleCloseImageExplainerModal: handleCloseImageExplainerModal }, `${isBookComplete}`), _jsx(GuidedTourModal, { isOpen: showGuidedTourModal, handleCloseModal: () => {
                    setShowGuidedTourModal(false);
                    try {
                        localStorage.setItem("unicorntales_tour", "1");
                    }
                    catch (e) {
                        console.error(e);
                    }
                }, handleStartTour: () => {
                    setStartGuidedTour(true);
                    setShowGuidedTourModal(false);
                } }), _jsx(ImageExplanationModal, { isOpen: showImageExplainerModal, handleCloseModal: handleCloseImageExplainerModal }), _jsx(MissingBookDataModal, { isOpen: showMissingBookDataModal, handleCloseModal: () => setShowMissingBookDataModal(false), handleFinishBookCreation: handleFinishBookCreation }), startGuidedTour && (_jsx(GuidedTour, { shouldPause: shouldPauseTour, handleShowImageExplainerModal: handleShowImageExplainerModal })), !isBookComplete ? (_jsx(BookLoadingScreenWithSubscribeBook, { bookId: book.id })) : null] }));
};
