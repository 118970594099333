"use client";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Footer as FlowbiteFooter } from "flowbite-react";
const links = [
    {
        path: `/terms-of-service`,
        text: "Terms of Service",
    },
    {
        path: `/privacy-policy`,
        text: "Privacy Policy",
    },
];
export const Footer = () => {
    return (_jsxs(FlowbiteFooter, { container: true, className: "flex justify-between relative z-10 bg-white border-t", children: [_jsx(FlowbiteFooter.Copyright, { by: "UnicornTales", href: "#", year: 2023 }), _jsx(FlowbiteFooter.LinkGroup, { children: links.map(({ path, text }) => {
                    return (_jsx(FlowbiteFooter.Link, { className: "mr-4", href: path, children: text }));
                }) })] }));
};
