export const productCostCents = {
    "credits-100": 150,
    "credits-300": 350,
    "credits-500": 550,
    "book-hard-8.5x11": 3000,
};
export const productCreditCents = {
    "credits-100": 100,
    "credits-300": 300,
    "credits-500": 500,
    "book-hard-8.5x11": 0,
};
export const productInfo = {
    "credits-100": {
        name: "100 Credits",
        description: "Enough to tune an existing book",
        cost: "1.50",
    },
    "credits-300": {
        name: "300 Credits",
        description: "Enough to create a new book with some tuning.",
        cost: "3.50",
    },
    "credits-500": {
        name: "500 Credits",
        description: "Enough to create a new book with lots of tuning",
        cost: "5.50",
    },
    "book-hard-8.5x11": {
        name: "Book",
        description: "A childrens book",
        cost: "30.00",
    },
};
