import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef } from "react";
const ImageContext = React.createContext({
    getResolvedCacheKey: () => null,
    getFetchCacheKey: () => Promise.resolve(null),
    setFetchCacheKey: () => {
        return;
    },
    setResolvedCacheKey: () => {
        return;
    },
});
export const useImageContext = (cacheKeySuffix = 0) => {
    const { getFetchCacheKey, getResolvedCacheKey, setFetchCacheKey, setResolvedCacheKey, } = React.useContext(ImageContext);
    return {
        getFetchCacheKey: (key) => getFetchCacheKey(`${key}-${cacheKeySuffix}`),
        getResolvedCacheKey: (key) => getResolvedCacheKey(`${key}-${cacheKeySuffix}`),
        setFetchCacheKey: (key, fetch) => setFetchCacheKey(`${key}-${cacheKeySuffix}`, fetch),
        setResolvedCacheKey: (key, value) => setResolvedCacheKey(`${key}-${cacheKeySuffix}`, value),
    };
};
export const ImageContextProvider = ({ children, }) => {
    const resolvedCache = useRef({});
    const fetchCache = useRef({});
    const getResolvedCacheKey = (key) => {
        return resolvedCache?.current?.[key];
    };
    const getFetchCacheKey = (key) => {
        return fetchCache?.current?.[key];
    };
    const setFetchCacheKey = (key, fetch) => {
        if (fetch === undefined) {
            delete fetchCache.current[key];
        }
        else {
            fetchCache.current[key] = fetch;
        }
    };
    const setResolvedCacheKey = (key, value) => {
        if (value === undefined) {
            delete resolvedCache.current[key];
        }
        else {
            resolvedCache.current[key] = value;
        }
    };
    return (_jsx(ImageContext.Provider, { value: {
            getResolvedCacheKey,
            getFetchCacheKey,
            setFetchCacheKey,
            setResolvedCacheKey,
        }, children: children }));
};
