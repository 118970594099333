"use client";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "flowbite-react";
import { useUserContext } from "./contexts/UserContext";
const PrivateRoute = ({ children }) => {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (user?.userId === null) {
            navigate(`/login?route=${encodeURI(location.pathname)}`);
        }
    }, [user]);
    return (_jsx(_Fragment, { children: user?.userId ? (children) : (_jsx("div", { className: "no-user-id h-screen flex items-center justify-center m-auto", children: _jsx(Spinner, { color: "pink", size: "xl" }) })) }));
};
PrivateRoute.displayName = "PrivateRoute";
export default PrivateRoute;
