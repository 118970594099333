"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "flowbite-react";
import { useBook } from "../../../hooks/useBook";
import { MakeStoryLoadingAndModalsLayer } from "./MakeStoryLoadingAndModalsLayer";
import { BookModelContextProvider } from "../../contexts/BookModelContext";
export const MakeStoryValidationLayer = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { book, hasFetchedBook } = useBook({
        bookId: params?.bookId || null,
        shouldSubscribe: false,
    });
    const showSpinner = book === null || (params?.bookId && !hasFetchedBook);
    useEffect(() => {
        if (hasFetchedBook && !book) {
            navigate("/");
        }
    }, [hasFetchedBook, book]);
    return showSpinner ? (_jsx("div", { className: "h-screen flex items-center justify-center m-auto", children: _jsx(Spinner, { color: "pink", size: "xl" }) })) : (_jsx(BookModelContextProvider, { bookId: book.id, children: _jsx(MakeStoryLoadingAndModalsLayer, { book: book }) }));
};
