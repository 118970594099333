import { useDocument } from "./useDocument";
import { getPageData } from "../helpers/page";
/** Must be used inside ModelContext */
export const useBookPage = ({ bookId, bookModel, pageKey, shouldSubscribe = true, }) => {
    const { document: page, hasFetchedDocument: hasFetchedBook, updateDocument: updateBook, updateDocumentState: updateBookState, } = useDocument({
        documentId: bookId,
        documentModel: bookModel,
        reducer: `data.pages.${pageKey}`,
        shouldSubscribe,
    });
    const updatePage = async (pageData) => {
        if (!page)
            return;
        const data = {};
        Object.entries(pageData).forEach(([field, value]) => {
            data[`data.pages.${pageKey}.${field}`] = value;
        });
        if (!pageData.error) {
            data[`data.pages.${pageKey}.error`] = null;
        }
        await updateBook(data);
    };
    const updatePageState = async (pageData) => {
        if (!page)
            return;
        const data = {};
        Object.entries(pageData).forEach(([field, value]) => {
            data[`data.pages.${pageKey}.${field}`] = value;
        });
        if (!pageData.error) {
            data[`data.pages.${pageKey}.error`] = null;
        }
        updateBookState(data);
    };
    return {
        page: getPageData(page, pageKey),
        hasFetchedBook,
        updatePageState,
        updatePage,
    };
};
